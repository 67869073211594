import { EventChangeScope, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

import apiService from "api/apiService";
import { prepareListSchemaForBackend } from "containers/lists/redux/utils";
import { IServerResponse } from "redux/actions/appActions";

import { TNewEventPayload } from "./types";

export const CALENDAR_EVENTS = "calendar/events";

export type TParamfetchEvents = {
  start_time?: Date;
  end_time?: Date;
  customer_id?: string;
  mobile_user_ids?: string[];
};

export type TfetchEventsFunc = (params: TParamfetchEvents) => Promise<
  IServerResponse<{
    events: TCalendarEvent[];
  }>
>;
export const fetchEventsApiCall: TfetchEventsFunc = (
  params: TParamfetchEvents
) => {
  const { mobile_user_ids, customer_id, start_time, end_time } = params;
  const EventsFetchQuery = {
    end_time,
    start_time,
    ...(mobile_user_ids && { mobile_user_ids }),
    ...(customer_id && { customer_id }),
  };

  return apiService.get(`${CALENDAR_EVENTS}`, EventsFetchQuery);
};

export type TfetchEventsSchemaFunc = () => Promise<
  IServerResponse<{
    schema: any[];
  }>
>;

export const fetchEventsSchemaApiCall: TfetchEventsSchemaFunc = () => {
  return apiService.get(`${CALENDAR_EVENTS}/schema`);
};

const formatEvents = (Events: TNewEventPayload[]): TCalendarEvent[] => {
  return _.map(Events, (Event) => {
    return {
      ...Event,
      start_time: new Date(Event.start_time),
      end_time: new Date(Event.end_time),
    };
  });
};
export type TcreateEventsFunc = (
  Events: TNewEventPayload[],
  options?: ICreateEventsApiCallOptions
) => Promise<
  IServerResponse<{
    events: TCalendarEvent[];
    success: boolean;
  }>
>;
interface ICreateEventsApiCallOptions {
  runInBackground?: boolean;
}

export const createEventsApiCall: TcreateEventsFunc = (Events, options) => {
  return apiService.post(
    `${CALENDAR_EVENTS}`,
    {
      calendar_events: formatEvents(Events),
    },
    undefined,
    undefined,
    options?.runInBackground
  );
};

export type TDeleteEventsFunc = (
  itemIds: string[],
  scope?: EventChangeScope
) => Promise<IServerResponse<{}>>;

export const deleteEventsApiCall: TDeleteEventsFunc = (itemIds, scope) => {
  return apiService.delete(`${CALENDAR_EVENTS}`, {
    ids: itemIds,
    applyTo: scope,
  });
};

export type TUpdateEventsFunc = (
  Events: TCalendarEvent[],
  scope?: EventChangeScope
) => Promise<IServerResponse<any>>;
export const updateEventsApiCall: TUpdateEventsFunc = (Events, scope) => {
  return apiService.patch(`${CALENDAR_EVENTS}`, {
    events: Events,
    applyTo: scope,
  });
};

export type TUpdateEventsSchemaFunc = (
  attributes: TCalendarEvent[]
) => Promise<IServerResponse<any>>;
export const updateEventsSchemaApiCall: TUpdateEventsSchemaFunc = (
  attributes
) => {
  return apiService.patch(`${CALENDAR_EVENTS}/schema`, {
    attributes: prepareListSchemaForBackend(
      JSON.parse(JSON.stringify(attributes))
    ),
  });
};

export type TCustomerReference = {
  id: string;
  name: string;
};
export type TFetchItemsReferencesPayload = {
  list_id: string;
  owner_ids: string[];
  limit?: number;
  offset?: number;
  search?: string;
};
export type TFetchItemsReferencesFunc = (
  payload: TFetchItemsReferencesPayload
) => Promise<IServerResponse<TCustomerReference[]>>;
export const fetchItemsReferencesApiCall: TFetchItemsReferencesFunc = ({
  list_id,
  owner_ids,
  limit,
  offset,
  search,
}) => {
  return apiService.get(`/lists/${list_id}/items/references`, {
    query: JSON.stringify({
      owner_ids,
      limit,
      offset,
      search,
    }),
  });
};
