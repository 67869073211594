import { Box, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { createGaugeBins } from "./utils/createGaugeBins";
import { GaugeColorRanges, getGaugeColor } from "./utils/getGaugeColors";

export interface ILinearGaugeProps {
  values: number[];
  minRange: number;
  maxRange: number;
  rangesCount?: number;
  labelFormatter?: (value: number) => string;
  reverse?: boolean;
  gaugeWidth?: number;
  gaugeHeight?: number;
  colorPalette?: GaugeColorRanges;
}

interface IStyleProps
  extends Pick<ILinearGaugeProps, "gaugeWidth" | "gaugeHeight"> {}
const useStyles = makeStyles({
  gauge: {
    height: (props: IStyleProps) => props.gaugeHeight,
    width: (props: IStyleProps) => props.gaugeWidth,
  },
  gaugeLabel: {
    textAlign: "left",
    fontSize: "8px",
    height: "auto",
    width: (props: IStyleProps) => `${props.gaugeWidth}px`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

function LinearGauge({
  values,
  maxRange,
  minRange,
  rangesCount = 5,
  reverse,
  labelFormatter,
  gaugeWidth = 45,
  gaugeHeight = 10,
  colorPalette,
}: ILinearGaugeProps) {
  const classes = useStyles({ gaugeWidth, gaugeHeight });
  const bins = createGaugeBins(values, rangesCount);
  const renderGauge = ([_min, max]: number[], i: number) => {
    const backgroundColor = reverse
      ? getGaugeColor(max, maxRange, minRange, colorPalette)
      : getGaugeColor(max, minRange, maxRange, colorPalette);

    return (
      <Fade in timeout={300 + i * 100} key={`animation-${colorPalette}-${i}`}>
        <Box
          display="grid"
          gridTemplateRows="1fr auto"
          key={`gauge-bin-${colorPalette}-${i}`}
        >
          <Box className={classes.gauge} style={{ backgroundColor }} />
          <Box className={classes.gaugeLabel}>
            {labelFormatter ? labelFormatter(max) : max}
          </Box>
        </Box>
      </Fade>
    );
  };

  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      width={gaugeWidth * rangesCount}
      data-testid="linear-gauge"
    >
      {_.map(reverse ? _.reverse(bins) : bins, renderGauge)}
    </Box>
  );
}

export default LinearGauge;
