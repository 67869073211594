import {
  ICustomersMapKpiData,
  TKpiMapLegend,
  TKpiMapLegendScaleType,
  TKpiMapLegendType,
} from "fieldpro-tools";
import _ from "lodash";

import { IKPI } from "model/entities/Dashboard";

export function getLegendFromChart(
  chart: IKPI<Array<ICustomersMapKpiData>>
): TKpiMapLegend | undefined {
  if (!chart.legend) return;
  const values: Array<number> = _.map(chart.data, chart.legend?.tag);
  const minValue = _.min(values) || 0;
  const maxValue = _.max(values) || 0;

  const { label, scale, tag, type } = chart.legend;

  return {
    values,
    min: minValue,
    max: maxValue,
    label: label || chart.title,
    scale: scale || TKpiMapLegendScaleType.LINEAR,
    type: type || TKpiMapLegendType.SCALE,
    tag,
    valueFormater: chart.value_format?.format,
  };
}
