import { ICustomersMapKpiData } from "fieldpro-tools";
import _ from "lodash";

import { TCustomerFeature } from "containers/customers/subcategories/map/CustomersMap/features/customers";
import { IKPI } from "model/entities/Dashboard";

import { getGaugeColor } from "./getGaugeColors";

export function getCustomerFeaturesFromChart(
  chart: IKPI<Array<ICustomersMapKpiData>>
): Array<TCustomerFeature> {
  const legendColumn = chart.legend?.tag || "";
  const numbers = _.compact(_.map(chart.data, (d) => d[legendColumn]));
  const minValue = _.min(numbers) || 0;
  const maxValue = _.max(numbers) || 0;
  const features = _.map(chart.data, (data): TCustomerFeature => {
    return {
      geometry: {
        coordinates: [data._location__lng, data._location__lat],
        type: "Point",
      },
      properties: {
        color: getGaugeColor(
          data[legendColumn] || minValue,
          minValue,
          maxValue
        ),
        customerId: data._id,
      },
      type: "Feature",
    };
  });
  return features;
}
