import Moment from "moment";

interface IGetBoardSlotDateTimeString {
  day: string;
  time: string;
  moment: typeof Moment;
  clientTimezone: string;
}
export function getBoardSlotDateTimeString({
  day,
  time,
  moment,
  clientTimezone,
}: IGetBoardSlotDateTimeString) {
  const momentDate = moment.tz(
    `${day}T${moment(time, "hA").format("HH:mm")}`,
    clientTimezone
  );
  const dateFormatted = momentDate.format("YYYY-MM-DDTHH:mm:ssZ");
  return {
    dateFormatted,
    momentDate,
  };
}
