import { Box, Button, makeStyles } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useSelector } from "react-redux";

import { getIsUserAManager } from "containers/calendar/redux/selectors";
import CalendarViewToggleButton from "containers/calendar/utils/CalendarViewToggleButton";
import { useCalendarRange, useTranslations } from "hooks";

import { styles } from "../../styles";

const useStyles = makeStyles(styles as any);

interface IListCalendarHeaderProps {
  onClickAddVisit: () => void;
}
const ListCalendarHeader: React.FC<IListCalendarHeaderProps> = ({
  onClickAddVisit,
}) => {
  const classes = useStyles();
  const { date } = useCalendarRange();
  const formattedDate = moment.utc(date);
  const lang = useTranslations();
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit;

  const isManager = useSelector(getIsUserAManager);

  return (
    <Box display={"flex"} position={"relative"}>
      <Box className={classes.title} flexGrow={3}>
        <Box>{inputVisitLang.customMessage.schedule}</Box>
        <Box>-</Box>
        <Box fontWeight={"600"}>
          {formattedDate.format("MMMM")} {formattedDate.format("YYYY")}
        </Box>
      </Box>
      <Box display={"flex"} gridColumnGap={"8px"}>
        {isManager && <CalendarViewToggleButton />}
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onClickAddVisit}
        >
          {
            lang.containers.calendar.subCategories.calendar.createEditModal
              .inputVisit.title
          }
        </Button>
      </Box>
    </Box>
  );
};

export default ListCalendarHeader;
