import { useEffect, useState } from "react";

import { Box, Grow, MenuItem, MenuList } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PlaceIcon from "@mui/icons-material/Place";
import _ from "lodash";
import { useSelector } from "react-redux";

import InputSelect from "components/Input/InputSelect";
import { fetchCustomersLegendAction } from "containers/customers/redux/actions";
import { getAllLegends } from "containers/customers/redux/selectors";
import { ICustomerLegendFE } from "containers/customers/redux/types";
import { allListsSelector } from "containers/lists/redux/selectors";
import { useActions, useTranslations } from "hooks";
import { IOption } from "model/application/components";

import DynamicalyRenderedMenuList from "./DynamicalyRenderedMenuList";
import { getMapLegendOptions } from "./utils";

interface IMapLegendProps {
  onClickCustomer?: (customerId: string) => void;
  changeLegendSelected?: (legend: string | undefined) => void;
  defaultValue?: string;
}
const MapLegend: React.FC<IMapLegendProps> = ({
  changeLegendSelected,
  defaultValue,
  onClickCustomer,
}) => {
  const lang = useTranslations();
  const fetchCustomersLegend = useActions(fetchCustomersLegendAction);
  const [legend, setLegend] = useState<ICustomerLegendFE | undefined>(
    undefined
  );
  const [selectedLegend, setSelectedLegend] = useState<string | undefined>(
    defaultValue
  );
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const allLegends = useSelector(getAllLegends);
  const [loading, setLoading] = useState(false);
  const allLists = useSelector(allListsSelector);
  const customersList = allLists.find((list) => list.id === "customer");
  const legendOptions: IOption[] = getMapLegendOptions({
    customersList,
    lang,
  });

  useEffect(() => {
    (async () => {
      if (!selectedLegend) return;
      setLoading(true);
      const storedLegend = allLegends?.find((l) => l.tag === selectedLegend);
      let l: ICustomerLegendFE | undefined;
      if (storedLegend) {
        l = storedLegend;
      } else {
        l = await fetchCustomersLegend({
          tag: selectedLegend,
        });
      }
      if (l) {
        setLegend(l);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLegend]);

  function getCategoryValues(selectedCategory: string | undefined) {
    if (legend && selectedCategory) {
      return legend.values[selectedCategory];
    }
    return undefined;
  }

  function buildCustomersInCategoryMenu() {
    if (!selectedCategory) return undefined;
    const values = getCategoryValues(selectedCategory);
    const keys = _.keys(values);
    return (
      <DynamicalyRenderedMenuList
        listSize={_.size(values)}
        maxItems={10}
        buildMenuItemFunction={(index) => {
          const customerId = keys[index];
          const customer = _.get(values, customerId);
          function handleCustomerClick() {
            if (onClickCustomer && customerId) {
              onClickCustomer(customerId);
            }
          }
          if (customer) {
            return (
              <MenuItem key={customerId} onClick={handleCustomerClick}>
                <Grow in>
                  <Box marginLeft={"20px"} fontSize={"16px"}>
                    {customer.name || ""}
                  </Box>
                </Grow>
              </MenuItem>
            );
          }
        }}
      />
    );
  }

  return (
    <Box
      display={"grid"}
      gridTemplateRows={"auto auto"}
      width={"250px"}
      overflow={"hidden"}
    >
      <Box marginTop={"20px"} padding={"0px 8px"}>
        <InputSelect
          placeholder={lang.genericTerms.none}
          name="legend"
          onChange={(opt) => {
            const optSelected = opt === "__CLEAR" ? undefined : opt;
            setSelectedLegend(optSelected);
            if (changeLegendSelected) {
              changeLegendSelected(optSelected);
            }
          }}
          options={legendOptions}
          value={selectedLegend}
          viewMode="INLINE"
        />
      </Box>
      <Box
        style={{
          overflowY: "auto",
          maxHeight: "350px",
          transition: "max-height 0.15s ease-out",
        }}
      >
        <Box paddingBottom={"20px"} overflow={"hidden"}>
          <MenuList>
            {loading &&
              _.times(5, (index) => {
                return (
                  <MenuItem key={index}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </MenuItem>
                );
              })}
            {!loading &&
              selectedLegend &&
              _.map(legend?.categories, (cat) => {
                return (
                  <Box key={`menuitem-${cat.key}`}>
                    <MenuItem
                      onClick={() => {
                        if (selectedCategory === cat.key) {
                          setSelectedCategory(undefined);
                        } else {
                          setSelectedCategory(cat.key);
                        }
                      }}
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <Box width={"100%"} padding={"0px 8px"}>
                        <Box
                          display={"grid"}
                          gridTemplateColumns={"auto 2fr auto"}
                          gridColumnGap={"4px"}
                          width={"100%"}
                        >
                          <Box>
                            <PlaceIcon
                              style={{ marginTop: "2px", color: cat.color }}
                              htmlColor={cat.color}
                              fontSize={"small"}
                            />
                          </Box>
                          <Box fontSize={"16px"}>{cat.label}</Box>
                          <Box fontSize={"16px"} fontWeight={"400"}>
                            {cat.count}
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>

                    {cat.key === selectedCategory &&
                      buildCustomersInCategoryMenu()}
                  </Box>
                );
              })}
          </MenuList>
        </Box>
      </Box>
    </Box>
  );
};

export default MapLegend;
