import { BUSINESS_ERROR } from "fieldpro-tools";
import { Dispatch } from "redux";

import { IChangeTagParams } from "containers/lists/redux/actions";
import { prepareListsForFrontend } from "containers/lists/redux/utils";
import {
  archivedAllResourcesLinkedToWf,
  prepareActivitiesForFrontend,
  prepareWorkflowsForFrontend,
  restoreAllResourcesLinkedToWf,
} from "containers/workflows/redux/utils";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import { IDynamicObjects } from "model/application/DynamicObjects";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IActivity, IWorkflow } from "model/entities/Workflow";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import { getLang } from "../../authentication/redux/selector";
import {
  createListSuccessAction,
  uploadFileFailureAction,
} from "../../lists/redux/actionCreators";
import { showNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationLevels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import {
  removeActivitiesFromAllTeamsSuccessAction,
  removeWorkflowsFromAllTeamsSuccessAction,
} from "../../teams/redux/actionCreators";
import {
  archiveActivityBeginActionCreator,
  archiveActivityFailureActionCreator,
  archiveActivitySuccessActionCreator,
  archiveWorkflowBeginActionCreator,
  archiveWorkflowFailureActionCreator,
  archiveWorkflowSuccessActionCreator,
  createActivityBeginActionCreator,
  createActivityFailureActionCreator,
  createActivityReportBeginActionCreator,
  createActivityReportFailureActionCreator,
  createActivityReportSuccessActionCreator,
  createActivitySuccessActionCreator,
  createWorkflowBeginActionCreator,
  createWorkflowFailureActionCreator,
  createWorkflowReportBeginActionCreator,
  createWorkflowReportFailureActionCreator,
  createWorkflowReportSuccessActionCreator,
  createWorkflowSuccessActionCreator,
  deleteActivityBeginActionCreator,
  deleteActivityFailureActionCreator,
  deleteActivityReportBeginActionCreator,
  deleteActivityReportFailureActionCreator,
  deleteActivityReportSuccessActionCreator,
  deleteActivitySuccessActionCreator,
  deleteWorkflowBeginActionCreator,
  deleteWorkflowFailureActionCreator,
  deleteWorkflowReportBeginActionCreator,
  deleteWorkflowReportFailureActionCreator,
  deleteWorkflowReportSuccessActionCreator,
  deleteWorkflowSuccessActionCreator,
  downloadReportRawDataBeginActionCreator,
  downloadReportRawDataFailureActionCreator,
  downloadReportRawDataSuccessActionCreator,
  editQuestionTagBeginAction,
  editQuestionTagFailureAction,
  editQuestionTagSuccessAction,
  fetchAllActivitiesBeginActionCreator,
  fetchAllActivitiesFailureActionCreator,
  fetchAllActivitiesSuccessActionCreator,
  fetchAllWorkflowsBeginActionCreator,
  fetchAllWorkflowsFailureActionCreator,
  fetchAllWorkflowsSuccessActionCreator,
  fetchAugmentedActivityReportsBeginActionCreator,
  fetchAugmentedActivityReportsFailureActionCreator,
  fetchAugmentedActivityReportsSuccessActionCreator,
  fetchWorkflowReportBeginActionCreator,
  fetchWorkflowReportFailureActionCreator,
  fetchWorkflowReportsBeginActionCreator,
  fetchWorkflowReportsFailureActionCreator,
  fetchWorkflowReportsSuccessActionCreator,
  fetchWorkflowReportSuccessActionCreator,
  reOrderWorkflowSuccessActionCreator,
  resetActivityReportSelectionAction,
  restoreActivityBeginActionCreator,
  restoreActivityFailureActionCreator,
  restoreActivitySuccessActionCreator,
  restoreWorkflowBeginActionCreator,
  restoreWorkflowFailureActionCreator,
  restoreWorkflowSuccessActionCreator,
  resumeWorkflowReportExecutionBeginActionCreator,
  resumeWorkflowReportExecutionFailureActionCreator,
  resumeWorkflowReportExecutionSuccessActionCreator,
  stopWorkflowReportExecutionBeginActionCreator,
  stopWorkflowReportExecutionFailureActionCreator,
  stopWorkflowReportExecutionSuccessActionCreator,
  updateActivityBeginActionCreator,
  updateActivityFailureActionCreator,
  updateActivityReportBeginActionCreator,
  updateActivityReportFailureActionCreator,
  updateActivityReportSuccessActionCreator,
  updateActivitySuccessActionCreator,
  updateWorkflowBeginActionCreator,
  updateWorkflowFailureActionCreator,
  updateWorkflowReportBeginActionCreator,
  updateWorkflowReportFailureActionCreator,
  updateWorkflowReportSuccessActionCreator,
  updateWorkflowSuccessActionCreator,
  uploadFileBeginAction,
  uploadFileSuccessAction,
} from "./actionCreators";
import {
  archiveActivityApiCall,
  archiveWorkflowApiCall,
  createActivityApiCall,
  createActivityReportApiCall,
  createWorkflowApiCall,
  createWorkflowReportApiCall,
  deleteActivityApiCall,
  deleteActivityReportApiCall,
  deleteWorkflowApiCall,
  deleteWorkflowReportApiCall,
  editQuestionTagApiCall,
  fetchAllActivitiesApiCall,
  fetchAllWorkflowsApiCall,
  fetchAugmentedActivityReportsApiCall,
  fetchDownloadReportRawDataApiCall,
  fetchWorkflowReportsApiCall,
  getActivityReportPdfUrlApiCall,
  IAugmentedActivityReport,
  IReportsFilters,
  reorderWorkflowApiCall,
  restoreActivityApiCall,
  restoreWorkflowApiCall,
  resumeWorkflowReportExecutionApiCall,
  stopWorkflowReportExecutionApiCall,
  updateActivityApiCall,
  updateActivityReportApiCall,
  updateWorkflowApiCall,
  updateWorkflowReportApiCall,
  uploadFileApiCall,
  uploadFileForWorkflowApiCall,
} from "./api";
import {
  deleteTriggerAction,
  IDeleteTriggerActionFunc,
} from "./triggers/actions";

/**
 * Action to create a workflow with the given name and payload. The payload is a parsed JSON
 * with the question teams
 * @param {Object} primary Workflow to create.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const createWorkflowAction: ICreateWorkflowActionFunc = (
  workflow,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(createWorkflowBeginActionCreator());
    return createWorkflowApiCall(workflow)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id, task_list } = data;

        dispatch(ajaxSuccessAction());
        workflow.id = id;
        dispatch(createWorkflowSuccessActionCreator(workflow, files));
        if (task_list)
          dispatch(
            createListSuccessAction(prepareListsForFrontend([task_list])[0])
          );
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.WORKFLOW,
              workflow.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateWorkflowError",
          error,
          createWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch workflows by client id
 * @returns {Function}
 */
export const fetchWorkflowsByClientAction: IFetchAllWorkflowsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(fetchAllWorkflowsBeginActionCreator());

      return fetchAllWorkflowsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { workflows } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllWorkflowsSuccessActionCreator(
              prepareWorkflowsForFrontend(workflows)
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchWorkflowsByClientError",
            error,
            fetchAllWorkflowsFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Update workflow
 * @param {Object} workflow Workflow to create.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const updateWorkflowAction: IUpdateWorkflowActionFunc = (
  workflow,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(updateWorkflowBeginActionCreator());

    return updateWorkflowApiCall(workflow)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateWorkflowSuccessActionCreator(workflow, files));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.WORKFLOW,
              workflow.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateWorkflowError",
          error,
          updateWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update workflow
 * @param {Object} workflow Workflow to create.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const reOrderWorkflowsAction: TReorderWorkflowsActionFunc = (
  workflowsIds
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return reorderWorkflowApiCall(workflowsIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());

        dispatch(reOrderWorkflowSuccessActionCreator(workflowsIds));

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.WORKFLOW
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateWorkflowError",
          error,
          updateWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete workflow action
 * @param {String} workflowId,
 * @returns {Function}
 */
export const deleteWorkflowAction: IDeleteWorkflowActionFunc = (workflowId) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWorkflowBeginActionCreator());
    return deleteWorkflowApiCall(workflowId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(removeWorkflowsFromAllTeamsSuccessAction([workflowId]));
        dispatch(deleteWorkflowSuccessActionCreator(workflowId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WORKFLOW,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWorkflowError",
          error,
          deleteWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Archive workflow action
 * @param {String} workflowId
 * @returns {Function}
 */
export const archiveWorkflowAction: IArchiveWorkflowActionFunc = (
  workflowId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveWorkflowBeginActionCreator());
    return archiveWorkflowApiCall(workflowId)
      .then((serverResponse) => {
        const response = extractDataAndCheckErrorStatus(serverResponse);

        dispatch(ajaxSuccessAction());

        //Remove wf from all teams
        dispatch(removeWorkflowsFromAllTeamsSuccessAction([workflowId]));
        dispatch(archiveWorkflowSuccessActionCreator(workflowId));

        //Archived all resources of the wf
        const { archived_activities, archived_lists, archived_dashboards } =
          response as {
            archived_activities: string[];
            archived_lists: string[];
            archived_dashboards: string[];
          };
        archivedAllResourcesLinkedToWf(dispatch, {
          dashboardIds: archived_dashboards,
          activitiesIds: archived_activities,
          listIds: archived_lists,
        });

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.WORKFLOW,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveWorkflowError",
          error,
          archiveWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Archive activity action
 * @param {String} activityId
 * @returns {Function}
 */
export const archiveActivityAction: IArchiveActivityActionFunc = (
  activityId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveActivityBeginActionCreator());
    return archiveActivityApiCall(activityId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(removeActivitiesFromAllTeamsSuccessAction([activityId]));
        dispatch(archiveActivitySuccessActionCreator(activityId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.ACTIVITY,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveActivityError",
          error,
          archiveActivityFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Restore workflow action
 * @param {String} workflowId
 * @returns {Function}
 */
export const restoreWorkflowAction: IRestoreWorkflowActionFunc = (
  workflowId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreWorkflowBeginActionCreator());
    return restoreWorkflowApiCall(workflowId)
      .then((serverResponse) => {
        const response = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreWorkflowSuccessActionCreator(workflowId));

        //Restored all resources of the wf
        const { restored_activities, restored_lists, restored_dashboards } =
          response as {
            restored_activities: string[];
            restored_lists: string[];
            restored_dashboards: string[];
          };
        restoreAllResourcesLinkedToWf(dispatch, {
          dashboardIds: restored_dashboards,
          activitiesIds: restored_activities,
          listIds: restored_lists,
        });

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.WORKFLOW,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreWorkflowError",
          error,
          restoreWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete workflow report action
 * @param {String} workflowId,
 * @param {String} workflowReportId,
 * @returns {Function}
 */
export const deleteWorkflowReportAction: IDeleteWorkflowReportActionFunc = (
  workflowId,
  workflowReportId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWorkflowReportBeginActionCreator());
    return deleteWorkflowReportApiCall(workflowId, workflowReportId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteWorkflowReportSuccessActionCreator(workflowReportId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WORKFLOW_REPORT,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWorkflowError",
          error,
          deleteWorkflowReportFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Restore activity action
 * @param {String} workflowId
 * @returns {Function}
 */
export const restoreActivityAction: IRestoreActivityActionFunc = (
  workflowId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreActivityBeginActionCreator());
    return restoreActivityApiCall(workflowId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreActivitySuccessActionCreator(workflowId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.ACTIVITY,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreActivityError",
          error,
          restoreActivityFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to create an activity with the given name and payload. The payload is a parsed JSON
 * with the question teams
 * @param {Object} activity Activity to create.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const createActivityAction: ICreateActivityActionFunc = (
  activity,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(createActivityBeginActionCreator());
    return createActivityApiCall(activity)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);

        dispatch(ajaxSuccessAction());
        activity.id = data.id;
        dispatch(createActivitySuccessActionCreator(activity, files));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.ACTIVITY,
              activity.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateActivityError",
          error,
          createActivityFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch activities by client id
 * @returns {Function}
 */
export const fetchActivitiesByClientAction: IFetchAllActivitiesActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(fetchAllActivitiesBeginActionCreator());

      return fetchAllActivitiesApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { activities } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllActivitiesSuccessActionCreator(
              prepareActivitiesForFrontend(activities)
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchActivitiesByClientError",
            error,
            fetchAllActivitiesFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Update workflow
 * @param {Object} activity Activity to update.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const updateActivityAction: IUpdateActivityActionFunc = (
  activity,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(updateActivityBeginActionCreator());

    return updateActivityApiCall(activity)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          updateActivitySuccessActionCreator(
            prepareActivitiesForFrontend([data.new_activity])[0],
            files
          )
        );
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.ACTIVITY,
              activity.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateActivityError",
          error,
          updateActivityFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete activity action
 * @param {String} activityId,ju2sqlgln6se
 * @returns {Function}
 */
export const deleteActivityAction: IDeleteActivityActionFunc = (activityId) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteActivityBeginActionCreator());
    return deleteActivityApiCall(activityId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(removeActivitiesFromAllTeamsSuccessAction([activityId]));
        dispatch(deleteActivitySuccessActionCreator(activityId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.ACTIVITY,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteActivityError",
          error,
          deleteActivityFailureActionCreator,
          currLang
        );
      });
  };
};

export const fetchAugmentedActivityReportsAction: IFetchAugmentedActivityReportsActionFunc =
  (activityId, filters, options) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAugmentedActivityReportsBeginActionCreator());

      return fetchAugmentedActivityReportsApiCall(activityId, filters)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());

          const { reports, totalCount } = data || {};
          dispatch(
            fetchAugmentedActivityReportsSuccessActionCreator(
              reports,
              totalCount,
              options
            )
          );

          if (!options?.hideNotification) {
            const message = getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DISPLAY,
              SUB_CATEGORIES.ACTIVITY_REPORT,
              data.reports.length ?? 0,
              true
            );
            dispatch(
              showNotificationActionCreator(
                notificationTypes.NOTIFICATION_SUCCESS,
                notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
                message
              )
            );
          }
          return reports;
        })
        .catch((error) => {
          dispatch(fetchAugmentedActivityReportsFailureActionCreator(error));
          treatErrorNotification(
            dispatch,
            "FetchAugmentedActivityReportsError",
            error,
            null,
            currLang
          );
        });
    };
  };

export type IFetchAugmentedActivityReportsWithoutStoring = (
  activityId: string,
  filters: any
) => IDispatchAndGetState<
  { reports: IAugmentedActivityReport[]; totalCount: number } | undefined
>;

export const fetchAugmentedActivityReportsWithoutStoring: IFetchAugmentedActivityReportsWithoutStoring =
  (activityId, filters) => {
    return async (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      try {
        dispatch(ajaxRequestAction());

        const serverResponse = await fetchAugmentedActivityReportsApiCall(
          activityId,
          filters
        );

        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { reports, totalCount } = data ?? {};

        // WE RETURN THE DATA WITHOUT DISPATCHING AN ACTION TO THE STORE
        // (This would pollute the data for the activity reports table)
        return { reports, totalCount };
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "FetchAugmentedActivityReportsWithoutStoring",
          error,
          null,
          currLang
        );
        return undefined;
      }
    };
  };

export const clearAugmentedActivityReportsAction = () => {
  return (dispatch: Dispatch) => {
    const action = fetchAugmentedActivityReportsSuccessActionCreator([], 0);
    dispatch(action);
  };
};

/**
 * Action to fetch an activityReport's PDF link
 */
export const getActivityReportPdfUrlAction: IGetActivityReportPdfUrlFunc = ({
  activityId,
  reportId,
}) => {
  return (dispatch) => {
    dispatch(ajaxRequestAction());
    return new Promise((resolve, reject) => {
      getActivityReportPdfUrlApiCall({ activityId, reportId })
        .then((response) => {
          const data = extractDataAndCheckErrorStatus(response);
          dispatch(ajaxSuccessAction());
          resolve(data.pdf_url);
        })
        .catch((e: any) => {
          console.error(e);
          reject(e);
        });
    });
  };
};

/**
 * Action to download report raw_data
 * @param {String} activityId Id of the activity where we want the reports
 * @param {Any} filters filters for the reports
 * @returns {Function}
 */
export const downloadActivityReportRawDataAction: TDownloadReportRawDataActionFunc =
  (activityId, filters) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(downloadReportRawDataBeginActionCreator());

      return fetchDownloadReportRawDataApiCall(activityId, filters)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(downloadReportRawDataSuccessActionCreator());
          if ((data as any).result) {
            dispatch(
              showNotificationActionCreator(
                notificationTypes.NOTIFICATION_SUCCESS,
                notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
                getSuccessNotificationMessage(
                  currLang,
                  LANG_ACTIONS.DOWNLOAD,
                  SUB_CATEGORIES.ACTIVITY_REPORT,
                  (data as any).result.mainTable.length - 1, // remove the header in the count
                  true
                )
              )
            );
          }

          return data;
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "DownloadReportRawDataError",
            error,
            downloadReportRawDataFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Action to fetch workflow_reports
 * @param {String} workflowId Id of the workflow where we want the workflow_reports
 * @param {Timestamp} startAt Starting date for the workflow_reports
 * @param {Timestamp} endAt Ending date for the workflow_reports
 * @returns {Function}
 */
export const fetchWorkflowReportsAction: IFetchWorkflowReportsActionFunc = (
  workflowId,
  filters
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchWorkflowReportsBeginActionCreator());
    return new Promise((resolve, reject) => {
      fetchWorkflowReportsApiCall(workflowId, filters)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchWorkflowReportsSuccessActionCreator(data.workflow_reports)
          );
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.DISPLAY,
                SUB_CATEGORIES.WORKFLOW_REPORT,
                data.workflow_reports.length,
                true
              )
            )
          );
          resolve(data.workflow_reports);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchWorkflowReportsError",
            error,
            fetchWorkflowReportsFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

/**
 * Action to fetch single workflow_report
 * @param {String} workflowId Id of the workflow where we want the workflow_reports
 * @param {String} workflowReportId Id of the workflow report to fetch
 * @returns {Function}
 */
export const fetchWorkflowReportAction: TFetchWorkflowReportActionFunc = (
  workflow_id,
  workflow_report_id
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchWorkflowReportBeginActionCreator());
    return new Promise((resolve, reject) => {
      fetchWorkflowReportsApiCall(workflow_id, { workflow_report_id })
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchWorkflowReportSuccessActionCreator(data.workflow_reports?.[0])
          );
          // dispatch(
          //   showNotificationActionCreator
          //     notificationTypes.NOTIFICATION_SUCCESS,
          //     notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
          //     getSuccessNotificationMessage(
          //       currLang,
          //       LANG_ACTIONS.DISPLAY,
          //       SUB_CATEGORIES.WORKFLOW_REPORT,
          //       data.workflow_reports.length,
          //       true
          //     )
          //   )
          // );
          resolve(data.workflow_reports);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchWorkflowReportsError",
            error,
            fetchWorkflowReportFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

/**
 * Action to fetch activity_reports
 * @param {String} workflowId Id of the workflow where we want the activity_reports
 * @param {String} activityReportId Id of the report where we want to fetch
 * @returns {Function}
 */
export const deleteActivityReportAction: IDeleteActivityReportActionFunc = (
  activityId,
  reportId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteActivityReportBeginActionCreator());
    return new Promise((resolve, reject) => {
      deleteActivityReportApiCall(activityId, reportId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(deleteActivityReportSuccessActionCreator(reportId));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.DELETE,
                SUB_CATEGORIES.ACTIVITY_REPORT,
                reportId,
                true
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "DeleteActivityReportError",
            error,
            deleteActivityReportFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export function resetActivityReports() {
  return (dispatch: Dispatch) => {
    dispatch(resetActivityReportSelectionAction());
  };
}
/**
 * Create activity_report
 * @param {Object} activity_report New values for the activity_report
 * @returns {Function}
 */
export const createActivityReportAction: ICreateActivityReportActionFunc = (
  activity_report
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(createActivityReportBeginActionCreator());

    return createActivityReportApiCall(activity_report)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(createActivityReportSuccessActionCreator(activity_report, []));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.ACTIVITY_REPORT,
              activity_report._id
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateActivityReportError",
          error,
          createActivityReportFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update activity_report
 * @param {Object} activity_report New values for the activity_report
 * @returns {Function}
 */
export const updateActivityReportAction: IUpdateActivityReportActionFunc = (
  activity_report
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(updateActivityReportBeginActionCreator());

    return updateActivityReportApiCall(activity_report)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          updateActivityReportSuccessActionCreator(data.activity_report)
        );
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.ACTIVITY_REPORT,
              activity_report._id
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateActivityReportError",
          error,
          updateActivityReportFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Create workflow report
 * @param {Object} workflowReport New values for the workflow report
 * @returns {Function}
 */
export const createWorkflowReportAction: ICreateWorkflowReportActionFunc = (
  workflowReport
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createWorkflowReportBeginActionCreator());
    return new Promise((resolve, reject) => {
      createWorkflowReportApiCall(workflowReport)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            createWorkflowReportSuccessActionCreator({
              ...workflowReport,
              ...(data.workflow_report as any),
            })
          );
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.WORKFLOW_REPORT,
                workflowReport._id
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateWorkflowReportError",
            error,
            createWorkflowReportFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};
/**
 * Update workflow_report
 * @param {Object} workflow_report New values for the workflow_report
 * @returns {Function}
 */
export const updateWorkflowReportAction: IUpdateWorkflowReportActionFunc = (
  workflow_report
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateWorkflowReportBeginActionCreator());
    return new Promise((resolve, reject) => {
      updateWorkflowReportApiCall(workflow_report)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            updateWorkflowReportSuccessActionCreator(data.workflow_report)
          );
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.WORKFLOW_REPORT,
                workflow_report._id
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateWorkflowReportError",
            error,
            updateWorkflowReportFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};
/**
 * Upload image
 * @param {Array} files file objects to upload
 * @param {Array} fileMetas array of meta info to give to the backend for each files
 * @returns {Promise}
 */
export const uploadActivityReportOrWorkflowFileAction: IUploadActivityReportFileActionFunc =
  (actionName: string, files: any, fileMetas: any, is_workflow?: boolean) => {
    return (dispatch: Dispatch, getState: any) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(uploadFileBeginAction());

      return new Promise(function (resolve, reject) {
        (is_workflow
          ? uploadFileForWorkflowApiCall(actionName, files, fileMetas)
          : uploadFileApiCall(actionName, files, fileMetas)
        )
          .then((serverResponse) => {
            const data = extractDataAndCheckErrorStatus(serverResponse);
            dispatch(ajaxSuccessAction());
            dispatch(uploadFileSuccessAction());
            dispatch(
              showNotificationActionCreator(
                notificationTypes.NOTIFICATION_SUCCESS,
                notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
                getSuccessNotificationMessage(
                  currLang,
                  LANG_ACTIONS.UPLOAD,
                  is_workflow
                    ? SUB_CATEGORIES.WORKFLOW
                    : SUB_CATEGORIES.ACTIVITY_REPORT
                )
              )
            );
            resolve(data as any);
          })
          .catch((error) => {
            treatErrorNotification(
              dispatch,
              "UploadFileError",
              error,
              uploadFileFailureAction,
              currLang
            );
            reject("Something went wrong");
          });
      });
    };
  };

/**
 * Stop execution of workflow_report
 * @param {Object} workflow_report workflow report to be stopped
 * @returns {Function}
 */
export const stopWorkflowReportExecutionAction: IStopWorkflowReportExecutionActionFunc =
  (wr) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(stopWorkflowReportExecutionBeginActionCreator());

      return stopWorkflowReportExecutionApiCall(wr.id, wr.workflow_id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            stopWorkflowReportExecutionSuccessActionCreator(
              data.workflow_report
            )
          );
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.STOP,
                SUB_CATEGORIES.WORKFLOW_REPORT,
                wr.id
              )
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "stopWorkflowReportExecutionError",
            error,
            stopWorkflowReportExecutionFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Resume execution of workflow_report
 * @param {Object} workflow_report workflow report to be resumed
 * @returns {Function}
 */
export const resumeWorkflowReportExecutionAction: IResumeWorkflowReportExecutionActionFunc =
  (wr) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(resumeWorkflowReportExecutionBeginActionCreator());

      return resumeWorkflowReportExecutionApiCall(wr.id, wr.workflow_id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            resumeWorkflowReportExecutionSuccessActionCreator(
              data.workflow_report
            )
          );
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.RESUME,
                SUB_CATEGORIES.WORKFLOW_REPORT,
                wr.id
              )
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "resumeWorkflowReportExecutionError",
            error,
            resumeWorkflowReportExecutionFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Edit question tag
 * @param old_tag The old tag for the list attribute to changed
 * @param new_tag The new tag for the list attribute to use
 * @param matrix_tag The tag of matrix question
 */
type IChangeQuestionTagFunc = (
  activityId: string,
  params: IChangeTagParams
) => IDispatchAndGetState<any>;
export const editQuestionTagAction: IChangeQuestionTagFunc = (
  activityId,
  params
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(editQuestionTagBeginAction());

    return new Promise((resolve, reject) => {
      editQuestionTagApiCall(activityId, params)
        .then((serverResponse) => {
          if (
            serverResponse["status"] === 200 &&
            serverResponse.data["code_response"] ===
              BUSINESS_ERROR.VALIDATION_ERROR
          ) {
            resolve(serverResponse.data);
          } else {
            extractDataAndCheckErrorStatus(serverResponse);
            dispatch(ajaxSuccessAction());
            dispatch(editQuestionTagSuccessAction(params, activityId));
            dispatch(
              showNotificationActionCreator(
                notificationTypes.NOTIFICATION_SUCCESS,
                notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
                getSuccessNotificationMessage(
                  currLang,
                  LANG_ACTIONS.CHANGE_TAG,
                  SUB_CATEGORIES.ACTIVITY,
                  undefined,
                  true
                )
              )
            );
            resolve({ changed: true });
          }
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "EditQuestionTagError",
            error,
            editQuestionTagFailureAction,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type ICreateWorkflowActionFunc = (
  workflow: IWorkflow,
  files: any[]
) => IDispatchAndGetState<void>;

export type IUpdateWorkflowActionFunc = (
  workflow: IWorkflow,
  files: any[]
) => IDispatchAndGetState<any>;

export type TReorderWorkflowsActionFunc = (
  workflowsIds: string[]
) => IDispatchAndGetState<any>;

export type IFetchAllWorkflowsActionFunc = () => IDispatchAndGetState<void>;

export type IDeleteWorkflowActionFunc = (
  workflowId: string
) => IDispatchAndGetState<void>;

export type IDeleteWorkflowReportActionFunc = (
  workflowReportId: string,
  workflowId: string
) => IDispatchAndGetState<void>;

export type IArchiveWorkflowActionFunc = (
  workflowId: string
) => IDispatchAndGetState<void>;

export type IArchiveActivityActionFunc = (
  activityId: string
) => IDispatchAndGetState<void>;

export type IRestoreWorkflowActionFunc = (
  workflowId: string
) => IDispatchAndGetState<void>;

export type IRestoreActivityActionFunc = (
  workflowId: string
) => IDispatchAndGetState<void>;

export type ICreateActivityActionFunc = (
  activity: IActivity,
  files: any[]
) => IDispatchAndGetState<void>;

export type IUpdateActivityActionFunc = (
  activity: IActivity,
  files: any[]
) => IDispatchAndGetState<any>;

export type IFetchAllActivitiesActionFunc = () => IDispatchAndGetState<void>;

export type IDeleteActivityActionFunc = (
  activityId: string,
  activityName: string
) => IDispatchAndGetState<void>;

export type IFetchActivityReportsActionFunc = (
  activityId: string,
  filters: IReportsFilters,
  hideNotification?: boolean
) => IDispatchAndGetState<IDynamicObjects[]>;

export interface IFetchAugmentedActivityReportsActionOptions {
  appendMode?: boolean;
  hideNotification?: boolean;
  clearExistingTableReports?: boolean;
}
export type IFetchAugmentedActivityReportsActionFunc = (
  activityId: string,
  filters: IReportsFilters,
  options?: IFetchAugmentedActivityReportsActionOptions
) => IDispatchAndGetState<any>;

export type IGetActivityReportPdfUrlFunc = ({
  activityId,
  reportId,
}: {
  activityId: string;
  reportId: string;
}) => IDispatchAndGetState<string>;

export type TDownloadReportRawDataActionFunc = (
  activityId: string,
  filters: any[]
) => IDispatchAndGetState<any>;

export type IFetchWorkflowReportsActionFunc = (
  workflowId: string,
  filters: any
) => IDispatchAndGetState<any>;

export type TFetchWorkflowReportActionFunc = (
  workflowId: string,
  workflowReportId: string
) => IDispatchAndGetState<any>;

export type IFetchJobLogsActionFunc = (
  workflowId: string
) => IDispatchAndGetState<void>;

export type ICreateActivityReportActionFunc = (
  activity_report: any
) => IDispatchAndGetState<any>;

export type IUpdateActivityReportActionFunc = (
  activity_report: any
) => IDispatchAndGetState<any>;

export type IDeleteActivityReportActionFunc = (
  activityId: string,
  reportId: string
) => IDispatchAndGetState<any>;

export type ICreateWorkflowReportActionFunc = (
  workflow_report: any
) => IDispatchAndGetState<any>;

export type IUpdateWorkflowReportActionFunc = (
  workflow_report: any
) => IDispatchAndGetState<any>;

export type IUploadActivityReportFileActionFunc = (
  actionName: string,
  files: any,
  fileMetas: any
) => IDispatchAndGetState<any>;

export type IStopWorkflowReportExecutionActionFunc = (
  workflow_report: any
) => IDispatchAndGetState<void>;

export type IResumeWorkflowReportExecutionActionFunc = (
  workflow_report: any
) => IDispatchAndGetState<void>;

export interface IWorkflowActions {
  createWorkflowAction: ICreateWorkflowActionFunc;
  deleteTriggerByClientAction: IDeleteTriggerActionFunc;
  fetchWorkflowsByClientAction: IFetchAllWorkflowsActionFunc;
  updateWorkflowAction: IUpdateWorkflowActionFunc;
  reOrderWorkflowsAction: TReorderWorkflowsActionFunc;
  deleteWorkflowAction: IDeleteWorkflowActionFunc;
  archiveWorkflowAction: IArchiveWorkflowActionFunc;
  createActivityReportAction: ICreateActivityReportActionFunc;
  updateActivityReportAction: IUpdateActivityReportActionFunc;
  archiveActivityAction: IArchiveActivityActionFunc;
  createWorkflowReportAction: ICreateWorkflowReportActionFunc;
  updateWorkflowReportAction: IUpdateWorkflowReportActionFunc;
  uploadActivityReportFileAction: IUploadActivityReportFileActionFunc;
  deleteWorkflowReportAction: IDeleteWorkflowReportActionFunc;
  restoreWorkflowAction: IRestoreWorkflowActionFunc;
}

const actions: IWorkflowActions = {
  createWorkflowAction,
  deleteTriggerByClientAction: deleteTriggerAction,
  updateWorkflowAction,
  reOrderWorkflowsAction,
  fetchWorkflowsByClientAction,
  deleteWorkflowAction,
  archiveWorkflowAction,
  archiveActivityAction,
  createActivityReportAction,
  updateActivityReportAction,
  createWorkflowReportAction,
  updateWorkflowReportAction,
  uploadActivityReportFileAction: uploadActivityReportOrWorkflowFileAction,
  restoreWorkflowAction,
  deleteWorkflowReportAction,
};

export default actions;
