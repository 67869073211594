import { useState } from "react";

import { MenuList } from "@material-ui/core";
import _ from "lodash";

import ScrollEdgeListener from "components/ScrollEdgeListener";

export interface IDynamicMenuListProps<_T> {
  buildMenuItemFunction: (index: number) => React.ReactNode;
  listSize: number;
  maxItems: number;
  Component?: React.ElementType;
}
type InferredProps<C> = C extends React.ComponentType<infer P> ? P : never;
type Props<T, C> = IDynamicMenuListProps<T> & InferredProps<C>;

/* ----------------------- DynamicalyRenderedMenuList ----------------------- */
//A very fast and efficient way to render a large list of items in a scrollable container.
function DynamicalyRenderedMenuList<
  T,
  C extends React.ElementType = typeof MenuList
>({
  listSize,
  maxItems,
  buildMenuItemFunction,
  Component = MenuList,
  ...rest
}: Props<T, C>) {
  const [itemsToShow, setItemsToShow] = useState(Math.min(listSize, maxItems));

  return (
    <Component {...rest}>
      {_.times(itemsToShow, (index) => {
        return buildMenuItemFunction(index);
      })}
      <ScrollEdgeListener
        callback={() => {
          if (itemsToShow < listSize) {
            setItemsToShow(Math.min(itemsToShow + maxItems, listSize));
          }
        }}
      />
    </Component>
  );
}

export default DynamicalyRenderedMenuList;
