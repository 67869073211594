import _ from "lodash";

export function createGaugeBins(values: number[], numBins: number): number[][] {
  const minValue = _.min(values) || 0;
  const maxValue = _.max(values) || 0;
  const binSize = (maxValue - minValue) / numBins;
  const bins: number[][] = [];
  for (let i = 0; i < numBins; i++) {
    const binMin = minValue + i * binSize;
    const binMax = binMin + binSize;
    bins.push([binMin, binMax]);
  }

  return bins;
}
