import React, { useEffect, useState } from "react";

import { Fade, makeStyles } from "@material-ui/core";

import { GreyDark } from "assets/colors";

const animationSpeed = 100;
const useStyles = makeStyles({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  },
  dot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: GreyDark,
    margin: "0 4px",
  },
});

const DotLoader: React.FC = () => {
  const classes = useStyles();
  const [fadeIndex, setFadeIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIndex((prevIndex) => (prevIndex + 1) % 4); // Rotate through the indices 0 to 3
    }, animationSpeed * 3);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes.loader}>
      <div>
        <Fade in={fadeIndex === 0} timeout={animationSpeed}>
          <div className={classes.dot} />
        </Fade>
      </div>
      <div>
        <Fade in={fadeIndex === 1} timeout={animationSpeed}>
          <div className={classes.dot} />
        </Fade>
      </div>
      <div>
        <Fade in={fadeIndex === 2} timeout={animationSpeed}>
          <div className={classes.dot} />
        </Fade>
      </div>
    </div>
  );
};

export default DotLoader;
