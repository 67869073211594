import { Box, makeStyles } from "@material-ui/core";
import * as d3 from "d3";
import {
  TKpiMapLegend,
  TKpiMapLegendScaleType,
  TKpiMapLegendType,
} from "fieldpro-tools";
import _ from "lodash";

import { GreyDark } from "assets/colors";

import LinearGauge from "./LinearGauge";
import { GaugeColorRanges } from "./utils/getGaugeColors";

const useStyles = makeStyles({
  legendContainer: {
    position: "absolute",
    bottom: 10,
    right: "50%",
    backgroundColor: "white",
    transform: "translateX(50%)",
    padding: "6px 16px 0px 16px",
  },
});
interface ILegendContainerProps {
  legend?: TKpiMapLegend;
}
function LegendContainer({ legend }: ILegendContainerProps) {
  const classes = useStyles();
  if (
    legend &&
    legend.type === TKpiMapLegendType.SCALE &&
    legend.scale === TKpiMapLegendScaleType.LINEAR
  ) {
    return (
      <Box className={classes.legendContainer}>
        <Box
          fontSize={12}
          color={GreyDark}
          fontWeight={500}
          textAlign={"center"}
        >
          {_.upperFirst(_.lowerCase(legend.label))}
        </Box>
        <LinearGauge
          colorPalette={GaugeColorRanges.YELLOW_TO_RED}
          values={legend.values}
          minRange={legend.min}
          maxRange={legend.max}
          rangesCount={_.size(legend.values) > 5 ? 5 : _.size(legend.values)}
          labelFormatter={(value) => {
            if (legend.valueFormater) {
              return d3.format(legend.valueFormater)(value);
            }
            return value.toString();
          }}
        />
      </Box>
    );
  }

  return null;
}

export default LegendContainer;
