import { useEffect, useState } from "react";

import { Box, Button } from "@material-ui/core";
import {
  CalendarEventStatus,
  CalendarEventType,
  IVisitEvent,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import CustomButton, { BUTTON_COLOR } from "components/Buttons/CustomButton";
import CustomCardTable from "components/Card/CustomCardTable";
import { BasicDialog } from "components/Dialog/BasicDialog";
import { getQuery } from "components/Filter/Filter.utils";
import PrimaryfiltersDropdownManager from "components/Filter/PrimaryFiltersDropdownManager";
import InputSelect from "components/Input/InputSelect";
import { mobileUserTeamMatesComposedSelector } from "containers/authentication/redux/selector";
import {
  fetchItemsReferencesApiCall,
  TFetchItemsReferencesPayload,
} from "containers/calendar/redux/api";
import { TNewEventPayload } from "containers/calendar/redux/types";
import { ListCalendarSubcategoryManager } from "containers/calendar/SubcategoryManager";
import { TCalendarFilterObject } from "containers/calendar/types";
import { computeNewEvent } from "containers/calendar/utils/computeNewEvent";
import {
  CreateEventsTableColumns,
  TEventsTableColumnData,
} from "containers/calendar/utils/CreateEventsTableColumns";
import { getEventsTableRows } from "containers/calendar/utils/getEventsTableRows";
import { getEventsToDisplay } from "containers/calendar/utils/getEventsToDisplay";
import {
  CALENDAR_FILTERS,
  prepareFiltersForListView,
} from "containers/calendar/utils/prepareFilters";
import {
  allListsSelector,
  getIsFetchingItemsForList,
} from "containers/lists/redux/selectors";
import { updateItemInArray } from "containers/workflows/subcategories/jobs/Jobscreen/notification/EmailNotificationConfigForm/utils/updateItemInArray";
import { useActions, useTranslations } from "hooks";
import { getCalendarPathFromRange } from "hooks/useCalendarRange";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";

import {
  createEventsActionFunction,
  deleteEventsActionFunction,
  updateEventsActionFunction,
} from "../../redux/actions";
import {
  getEventRequiresApproval,
  getEvents,
  getIsFetchingEvents,
} from "../../redux/selectors";
import AddVisitDialog from "../AddVisitDialog";
import CalendarListViewHeader from "./ListCalendarHeader";

const ListCalendar = () => {
  const [isVisitDialogOpen, setIsVisitDialogOpen] = useState(false);
  const eventsNeedApproval = useSelector(getEventRequiresApproval);
  const eventsStored = useSelector(getEvents);
  const [events, setEvents] = useState(eventsStored);

  const [newEventObject, setNewEventObject] = useState<Partial<TCalendarEvent>>(
    {
      start_time: new Date(),
      status: eventsNeedApproval
        ? CalendarEventStatus.PENDING
        : CalendarEventStatus.PLANNED,
    }
  );
  const lang = useTranslations();
  const history = useHistory();

  const lists = useSelector(allListsSelector);
  const customersList = _.find(lists, { id: "customer" });
  const [customerOptions, setCustomerOptions] = useState<IOption<string>[]>([]);

  const allMobileTeamMates = useSelector(mobileUserTeamMatesComposedSelector);

  const isFetchingEvents = useSelector(getIsFetchingEvents);
  const isFetchingItems = useSelector(getIsFetchingItemsForList);

  const [updateEventsAction, createEventsAction, deleteEventAction] =
    useActions([
      updateEventsActionFunction,
      createEventsActionFunction,
      deleteEventsActionFunction,
    ]);

  /* --------------------------------- FILTERS -------------------------------- */

  const [filterQuery, setFilterQuery] = useState<TCalendarFilterObject>({});
  const filters = prepareFiltersForListView({
    filterQuery,
    lang,
  });

  const eventsFilteredByDate = filterEventsByDate(
    _.get(filterQuery, [CALENDAR_FILTERS.DATE, "startDate"]),
    _.get(filterQuery, [CALENDAR_FILTERS.DATE, "endDate"]),
    events
  );
  const finalEvents = getEventsToDisplay({
    allowDisplayingEvents: true,
    events: eventsFilteredByDate,
    filterQuery,
  });

  const onChangeFilters = async (filters: IFilter<any>[]) => {
    const query = getQuery(filters);
    setFilterQuery(query);

    const dateFilter = query[CALENDAR_FILTERS.DATE];
    if (dateFilter) {
      const startDate = dateFilter.startDate;
      const newUrl = `?calendar=${getCalendarPathFromRange(
        {
          date: startDate,
          mode: "week",
        },
        "MODE/YYYY/MM/DD"
      )}`;
      history.push(newUrl);
    }
  };

  /* ----------------------------- CRUD OPERATIONS ---------------------------- */
  const onChangeStatus = async (
    selectedEvents: TCalendarEvent[],
    status: CalendarEventStatus
  ) => {
    let eventsCopy = events;
    const ids = _.map(selectedEvents, "id");
    const eventsToUpdate = eventsCopy.filter((event) => {
      return ids.includes(event.id);
    });

    _.forEach(eventsToUpdate, (event: TCalendarEvent) => {
      event.status = status;
      eventsCopy = updateItemInArray(eventsCopy, event, {
        id: event.id,
      });
    });

    setEvents(eventsCopy);
    try {
      await updateEventsAction(eventsToUpdate, undefined, false);
    } catch {
      setEvents(eventsStored);
    }
  };

  const onDeleteEvents = async (selectedEvents: TCalendarEvent[]) => {
    const ids = _.compact(_.map(selectedEvents, (event) => event.id));
    try {
      if (!_.isEmpty(ids)) {
        await deleteEventAction(ids);
      }
    } catch {
      setEvents(eventsStored);
    }
  };

  const onUpdateFieldUser = async () => {
    if (!newEventObject?.assigned_to) return;
    const query: TFetchItemsReferencesPayload = {
      list_id: "customer",
      owner_ids: [newEventObject?.assigned_to],
      limit: 1000,
      offset: 0,
    };
    fetchItemsReferencesApiCall(query)
      .then((response) => {
        const { data } = response.data;
        setCustomerOptions(
          _.map(data, (reference) => ({
            key: reference.id,
            label: reference.name,
          }))
        );
      })
      .catch(() => {
        setCustomerOptions([]);
      });
  };
  const onSaveVisit = async (event: IVisitEvent) => {
    const newEvent = {
      ...event,
      type: CalendarEventType.VISIT,
      status: eventsNeedApproval
        ? CalendarEventStatus.PENDING
        : CalendarEventStatus.PLANNED,
    };
    const computedEvent = computeNewEvent(newEvent);
    setNewEventObject({
      start_time: new Date(),
    });
    await createEventsAction([computedEvent as TNewEventPayload]);
  };

  useEffect(() => {
    onUpdateFieldUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEventObject?.assigned_to]);

  useEffect(() => {
    setEvents(eventsStored);
  }, [eventsStored]);

  const data = getEventsTableRows({
    customersList,
    events: finalEvents,
    lang,
    mobileUsers: allMobileTeamMates,
    onChangeStatus,
  });

  const columns = CreateEventsTableColumns({ lang });

  const tableActions = getTableActions({
    onChangeStatus,
    onDeleteEvents,
    lang,
  });

  return (
    <ListCalendarSubcategoryManager>
      <Box display={"flex"} gridRowGap={8} flexDirection={"column"}>
        <CalendarListViewHeader
          onClickAddVisit={() => {
            setIsVisitDialogOpen(true);
          }}
        />
        <CustomCardTable
          filterDropdownManager={
            <PrimaryfiltersDropdownManager
              filters={filters}
              onChangeFilters={onChangeFilters}
            />
          }
          actions={tableActions}
          defaultRowsPerPage={10}
          data={data}
          columnTypes={columns}
          isLoading={isFetchingEvents || isFetchingItems}
          defaultSorting={{
            order: "desc",
            orderBy: "start_time",
          }}
        />
      </Box>

      {/* Visit dialog */}
      <BasicDialog open={isVisitDialogOpen} minWidth={"600px"}>
        <AddVisitDialog
          customerOptions={customerOptions}
          events={events}
          handleClose={() => {
            setIsVisitDialogOpen(false);
          }}
          onAddVisit={(event) => {
            onSaveVisit(event);
            setIsVisitDialogOpen(false);
          }}
          viewMode="CREATE"
          initEvent={newEventObject as TNewEventPayload}
        >
          <InputSelect
            title="Mobile user"
            name="customer"
            onChange={(opt) => {
              setNewEventObject({
                assigned_to: opt,
              });
            }}
            options={_.map(allMobileTeamMates, (user) => {
              return {
                label: user.name,
                key: user.id,
              };
            })}
            value={newEventObject?.assigned_to}
          />
        </AddVisitDialog>
      </BasicDialog>
    </ListCalendarSubcategoryManager>
  );
};

export default ListCalendar;

const filterEventsByDate = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  events: TCalendarEvent[]
) => {
  if (!startDate || !endDate) {
    return events;
  }
  return _.filter(events, (event: TCalendarEvent) => {
    return (
      moment(event.start_time).isSameOrAfter(startDate) &&
      moment(event.end_time).isSameOrBefore(endDate)
    );
  });
};

interface IGetTableActions {
  onChangeStatus: (
    changedEvents: TCalendarEvent[],
    status: CalendarEventStatus
  ) => Promise<void>;
  onDeleteEvents: (events: TCalendarEvent[]) => Promise<void>;
  lang: TLang;
}
const getTableActions = ({
  onChangeStatus,
  onDeleteEvents,
  lang,
}: IGetTableActions): ITableAction[] => [
  {
    label: "_",
    action: (events: TEventsTableColumnData[]) => {
      onChangeStatus(
        _.filter(_.map(events, columnEventToBasicEvent), (event) => {
          return ![
            CalendarEventStatus.COMPLETED,
            CalendarEventStatus.PLANNED,
          ].includes(event.status || CalendarEventStatus.PLANNED);
        }),
        CalendarEventStatus.PLANNED
      );
    },
    actionType: ACTION_TYPE.CUSTOM,
    component: (
      <Button variant="contained" color="secondary" disableElevation>
        {lang.genericTerms.approve}
      </Button>
    ),
  },
  {
    label: "_",
    action: (events: TEventsTableColumnData[]) => {
      onChangeStatus(
        _.filter(_.map(events, columnEventToBasicEvent), (event) => {
          return ![
            CalendarEventStatus.DECLINED,
            CalendarEventStatus.COMPLETED,
          ].includes(event.status || CalendarEventStatus.PLANNED);
        }),
        CalendarEventStatus.DECLINED
      );
    },
    actionType: ACTION_TYPE.CUSTOM,
    component: (
      <Button variant="outlined" color="secondary" disableElevation>
        {lang.genericTerms.decline}
      </Button>
    ),
  },
  {
    label: "_",
    action: (events: TCalendarEvent[]) => {
      onDeleteEvents(events);
    },
    actionType: ACTION_TYPE.CUSTOM,
    component: (
      <CustomButton
        type={ACTION_TYPE.DELETE}
        text={lang.genericTerms.delete}
        color={BUTTON_COLOR.ERROR}
      />
    ),
  },
];

function columnEventToBasicEvent(event: TEventsTableColumnData) {
  const convertedEvent: TCalendarEvent = {
    ..._.omit(event, [
      "customer_name",
      "field_user_name",
      "phone_number",
      "status",
      "statusText",
    ]),
    status: event.statusText,
  };
  return convertedEvent;
}
