import Moment from "moment";

import { IClient } from "model/entities/Client";
const EVENT_CALENDAR_START_HOUR = 6;
const EVENT_CALENDAR_END_HOUR = 18;
export function getTimesOfDay(client: IClient, moment: typeof Moment) {
  const times: string[] = [];
  // eslint-disable-next-line prefer-const
  let { maxEventEndTime, minEventStartTime } = getCalendarWorkingHours(
    client,
    moment
  );

  while (minEventStartTime.isSameOrBefore(maxEventEndTime)) {
    times.push(minEventStartTime.format("hA"));
    minEventStartTime = minEventStartTime.add(1, "hour");
  }

  return times;
}

export function getCalendarWorkingHours(
  client: IClient,
  moment: typeof Moment
) {
  const { time_slots_start, time_slots_stop } = client || {};

  if (time_slots_start && time_slots_stop) {
    const timeSlotsStart = moment(time_slots_start);
    const timeSlotsStop = moment(time_slots_stop);
    const startHour = timeSlotsStart.hour();
    const startMinute = timeSlotsStart.minute();
    const stopHour = timeSlotsStop.hour();
    const stopMinute = timeSlotsStop.minute();

    const minEventStartTime = moment({
      hour: startHour,
      minute: startMinute,
    });
    const maxEventEndTime = moment({
      hour: stopHour,
      minute: stopMinute,
    });
    if (startHour > stopHour) {
      maxEventEndTime.add(1, "day");
    }
    // if (startHour == stopHour) {
    //   maxEventEndTime.add(1, "day");
    //   maxEventEndTime.subtract(1, "hour");
    // }
    return {
      minEventStartTime,
      maxEventEndTime,
    };
  }
  return {
    minEventStartTime: moment({
      hour: EVENT_CALENDAR_START_HOUR,
    }),
    maxEventEndTime: moment({
      hour: EVENT_CALENDAR_END_HOUR,
    }),
  };
}

export function advanceDateToToday(
  date: Date | Moment.Moment,
  moment: typeof Moment
) {
  const dateMoment = moment(date).clone();

  return moment({
    hour: dateMoment.hour(),
    minute: dateMoment.minute(),
  });
}
