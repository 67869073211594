import { ITableSorting } from "components/Table/CustomTable/CustomTable";
import { IChangeTagParams } from "containers/lists/redux/actions";
import {
  IActivity,
  IActivityReport,
  IWorkflow,
  IWorkflowReportWithStepOverview,
} from "model/entities/Workflow";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import { IFetchAugmentedActivityReportsActionOptions } from "./actions";
import * as types from "./actionTypes";
import { IAugmentedActivityReport } from "./api";

/**
 * Action creator dispatched when creating a workflow action is started
 * @returns {Object}
 */
export function createWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_WORKFLOW_BEGIN,
  };
}

/**
 * Failure/Error action creator when errors are caught when creating a workflow
 * @param {Object} error
 * @returns {Object}
 */
export function createWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_WORKFLOW_FAILURE,
    error,
  };
}

export interface ICreateWorkflowSuccessActionCreator extends IAction {
  workflow: IWorkflow;
  files: any[];
}

export const createWorkflowSuccessActionCreator = (
  workflow: IWorkflow,
  files: any[]
): ICreateWorkflowSuccessActionCreator => {
  return {
    type: types.CREATE_WORKFLOW_SUCCESS,
    workflow,
    files,
  };
};

/**
 * Fetch all workflows begin action creator
 * @returns {Object}
 */
export function fetchAllWorkflowsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_ALL_WORKFLOWS_BEGIN,
  };
}

/**
 * Fetch all workflows failure action
 * @param {Object} error
 * @returns {Object}
 */
export function fetchAllWorkflowsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_ALL_WORKFLOWS_FAILURE,
    error,
  };
}

export interface IFetchAllWorkflowsSuccessActionCreator extends IAction {
  workflows: IWorkflow[];
}

/**
 * Fetch all workflow success action
 * @param {Array} workflows
 * @returns {Object}
 */
export function fetchAllWorkflowsSuccessActionCreator(
  workflows: IWorkflow[]
): IFetchAllWorkflowsSuccessActionCreator {
  return {
    type: types.FETCH_ALL_WORKFLOWS_SUCCESS,
    workflows,
  };
}

/**
 * Creates update workflow action when a workflow is being updated
 * @returns {Object}
 */
export function updateWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an update workflow failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_WORKFLOW_FAILURE,
    error,
  };
}

export interface IUpdateWorkflowSuccessActionCreator extends IAction {
  workflow: IWorkflow;
  files: any[];
}

/**
 * creates a success action when we have successfully updated a workflow, pass in the updated workflow
 * @param {Object} primary Updated primary workflow object (either a default or a generator workflow)
 * @param {Object} secondary Updated secondary workflow object (task workflow, can be null)
 * @returns {Object}
 */
export function updateWorkflowSuccessActionCreator(
  workflow: IWorkflow,
  files: any[]
): IUpdateWorkflowSuccessActionCreator {
  return {
    type: types.UPDATE_WORKFLOW_SUCCESS,
    workflow,
    files,
  };
}

export interface IReorderWorkflowSuccessActionCreator extends IAction {
  workflowsIds: string[];
}
export function reOrderWorkflowSuccessActionCreator(
  workflowsIds: string[]
): IReorderWorkflowSuccessActionCreator {
  return {
    type: types.REORDER_WORKFLOW_SUCCESS,
    workflowsIds,
  };
}

/**
 * Creates an action when we have started the deletion process for deleting a workflow
 * @returns {Object}
 */
export function deleteWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when deleting an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function deleteWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_WORKFLOW_FAILURE,
    error,
  };
}

export interface IDeleteWorkflowSuccessActionCreator extends IAction {
  workflowId: string;
}

/**
 * Creates success action when we have successfully deleted a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function deleteWorkflowSuccessActionCreator(
  workflowId: string
): IDeleteWorkflowSuccessActionCreator {
  return {
    type: types.DELETE_WORKFLOW_SUCCESS,
    workflowId,
  };
}

/**
 * Creates an action when we have started the archive process for archiving a workflow
 * @returns {Object}
 */
export function archiveWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.ARCHIVE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an action when we have started the archive process for archiving a activity
 * @returns {Object}
 */
export function archiveActivityBeginActionCreator(): IBeginAction {
  return {
    type: types.ARCHIVE_ACTIVITY_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when archiving an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function archiveWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.ARCHIVE_WORKFLOW_FAILURE,
    error,
  };
}

/**
 * Creates an error action object if we encounter and error when archiving an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function archiveActivityFailureActionCreator(error: any): IActionError {
  return {
    type: types.ARCHIVE_ACTIVITY_FAILURE,
    error,
  };
}

export interface IArchiveWorkflowSuccessActionCreator extends IAction {
  workflowId: string;
}

/**
 * Creates success action when we have successfully archive a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function archiveWorkflowSuccessActionCreator(
  workflowId: string
): IArchiveWorkflowSuccessActionCreator {
  return {
    type: types.ARCHIVE_WORKFLOW_SUCCESS,
    workflowId,
  };
}

export interface IArchiveActivitySuccessActionCreator extends IAction {
  activityId: string;
}

/**
 * Creates success action when we have successfully archive a workflow
 * @param {String} activityId
 * @returns {Object}
 */
export function archiveActivitySuccessActionCreator(
  activityId: string
): IArchiveActivitySuccessActionCreator {
  return {
    type: types.ARCHIVE_ACTIVITY_SUCCESS,
    activityId,
  };
}

/**
 * Creates an action when we have started the restore process for restoring a workflow
 * @returns {Object}
 */
export function restoreWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.RESTORE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an action when we have started the restore process for restoring a workflow
 * @returns {Object}
 */
export function restoreActivityBeginActionCreator(): IBeginAction {
  return {
    type: types.RESTORE_ACTIVITY_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when restoring an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function restoreWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.RESTORE_WORKFLOW_FAILURE,
    error,
  };
}

/**
 * Creates an error action object if we encounter and error when restoring an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function restoreActivityFailureActionCreator(error: any): IActionError {
  return {
    type: types.RESTORE_ACTIVITY_FAILURE,
    error,
  };
}

export interface IRestoreWorkflowSuccessActionCreator extends IAction {
  workflowId: string;
}

/**
 * Creates success action when we have successfully restore a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function restoreWorkflowSuccessActionCreator(
  workflowId: string
): IRestoreWorkflowSuccessActionCreator {
  return {
    type: types.RESTORE_WORKFLOW_SUCCESS,
    workflowId,
  };
}

export interface IRestoreActivitySuccessActionCreator extends IAction {
  activityId: string;
}
/**
 * Creates success action when we have successfully restore a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function restoreActivitySuccessActionCreator(
  activityId: string
): IRestoreActivitySuccessActionCreator {
  return {
    type: types.RESTORE_ACTIVITY_SUCCESS,
    activityId,
  };
}

/**
 * Action creator dispatched when creating a activity action is started
 * @returns {Object}
 */
export function createActivityBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_ACTIVITY_BEGIN,
  };
}

/**
 * Failure/Error action creator when errors are caught when creating a activity
 * @param {Object} error
 * @returns {Object}
 */
export function createActivityFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_ACTIVITY_FAILURE,
    error,
  };
}

export interface ICreateActivitySuccessActionCreator extends IAction {
  activity: IActivity;
  files: any[];
}

export const createActivitySuccessActionCreator = (
  activity: IActivity,
  files: any[]
): ICreateActivitySuccessActionCreator => {
  return {
    type: types.CREATE_ACTIVITY_SUCCESS,
    activity,
    files,
  };
};

/**
 * Fetch all activities begin action creator
 * @returns {Object}
 */
export function fetchAllActivitiesBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_ALL_ACTIVITIES_BEGIN,
  };
}

/**
 * Fetch all activities failure action
 * @param {Object} error
 * @returns {Object}
 */
export function fetchAllActivitiesFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_ALL_ACTIVITIES_FAILURE,
    error,
  };
}

export interface IFetchAllActivitiesSuccessActionCreator extends IAction {
  activities: IActivity[];
}

/**
 * Fetch all activity success action
 * @param {Array} activities
 * @returns {Object}
 */
export function fetchAllActivitiesSuccessActionCreator(
  activities: IActivity[]
): IFetchAllActivitiesSuccessActionCreator {
  return {
    type: types.FETCH_ALL_ACTIVITIES_SUCCESS,
    activities,
  };
}

/**
 * Creates update activity action when a activity is being updated
 * @returns {Object}
 */
export function updateActivityBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_ACTIVITY_BEGIN,
  };
}

/**
 * Creates an update activity failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateActivityFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_ACTIVITY_FAILURE,
    error,
  };
}

export interface IUpdateActivitySuccessActionCreator extends IAction {
  activity: IActivity;
  files: any[];
}

/**
 * creates a success action when we have successfully updated a activity, pass in the updated activity
 * @param {Object} activity Updated activity object (either a default or a generator activity)
 * @returns {Object}
 */
export function updateActivitySuccessActionCreator(
  activity: IActivity,
  files: any[]
): IUpdateActivitySuccessActionCreator {
  return {
    type: types.UPDATE_ACTIVITY_SUCCESS,
    activity,
    files,
  };
}

/**
 * Creates an action when we have started the deletion process for deleting a activity
 * @returns {Object}
 */
export function deleteActivityBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_ACTIVITY_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when deleting an activity
 * @param {Object} error Error object
 * @returns {Object}
 */
export function deleteActivityFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_ACTIVITY_FAILURE,
    error,
  };
}

export interface IDeleteActivitySuccessActionCreator extends IAction {
  activityId: string;
}

/**
 * Creates success action when we have successfully deleted a activity
 * @param {String} activityId
 * @returns {Object}
 */
export function deleteActivitySuccessActionCreator(
  activityId: string
): IDeleteActivitySuccessActionCreator {
  return {
    type: types.DELETE_ACTIVITY_SUCCESS,
    activityId,
  };
}

/**
 * Creates delete activity_report action when a activity_report is being deleted
 * @returns {Object}
 */
export function deleteActivityReportBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_ACTIVITY_REPORT_BEGIN,
  };
}

/**
 * Creates an delete activity_report failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteActivityReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_ACTIVITY_REPORT_FAILURE,
    error,
  };
}

export interface IDeleteActivityReportSuccessActionCreator extends IAction {
  reportId: string;
}

/**
 * creates a success action when we have successfully deleted reports, pass in the deleted reports
 * @returns {Object}
 */
export function deleteActivityReportSuccessActionCreator(
  reportId: string
): IDeleteActivityReportSuccessActionCreator {
  return {
    type: types.DELETE_ACTIVITY_REPORT_SUCCESS,
    reportId,
  };
}

/**
 * Creates fetch activity reports action when a reports are being fetched
 * @returns {Object}
 */
export function fetchActivityReportsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_ACTIVITY_REPORTS_BEGIN,
  };
}

/**
 * Creates a fetch activity reports failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchActivityReportsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_ACTIVITY_REPORTS_FAILURE,
    error,
  };
}

export interface IFetchActivityReportsSuccessActionCreator extends IAction {
  reports: IActivityReport[];
  totalCount: number;
  appendMode?: boolean;
}

export interface IFetchAugmentedActivityReportsSuccessActionCreator
  extends IAction {
  reports: IAugmentedActivityReport[];
  totalCount: number;
  options?: IFetchAugmentedActivityReportsActionOptions;
}

/**
 * Download activity report raw data action when action is being done
 * @returns {Object}
 */
export function downloadReportRawDataBeginActionCreator(): IBeginAction {
  return {
    type: types.DOWNLOAD_REPORT_RAW_DATA_BEGIN,
  };
}

/**
 * Creates a download report raw data failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function downloadReportRawDataFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DOWNLOAD_REPORT_RAW_DATA_FAILURE,
    error,
  };
}

export interface IDownloadReportRawDataSuccessActionCreator extends IAction {}

/**
 * creates a success action when we have successfully downloaded report raw data, pass in the fetched activity_reports
 * @param {Array} data data fetched
 * @returns {Object}
 */
export function downloadReportRawDataSuccessActionCreator(): IDownloadReportRawDataSuccessActionCreator {
  return {
    type: types.DOWNLOAD_REPORT_RAW_DATA_SUCCESS,
  };
}

export function fetchAugmentedActivityReportsSuccessActionCreator(
  reports: IAugmentedActivityReport[],
  totalCount: number,
  options?: IFetchAugmentedActivityReportsActionOptions
): IFetchAugmentedActivityReportsSuccessActionCreator {
  return {
    type: types.FETCH_AUGMENTED_ACTIVITY_REPORTS_SUCCESS,
    reports,
    totalCount,
    options,
  };
}
export function fetchAugmentedActivityReportsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_AUGMENTED_ACTIVITY_REPORTS_BEGIN,
  };
}
export function fetchAugmentedActivityReportsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_AUGMENTED_ACTIVITY_REPORTS_FAILURE,
    error,
  };
}

/**
 * Creates an action when we have started the deletion process for deleting a workflow report
 * @returns {Object}
 */
export function deleteWorkflowReportBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_WORKFLOW_REPORT_BEGIN,
  };
}
/**
 * Creates an error action object if we encounter and error when deleting a workflow report
 * @param {Object} error Error object
 * @returns {Object}
 */
export function deleteWorkflowReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_WORKFLOW_REPORT_FAILURE,
    error,
  };
}

export interface IDeleteWorkflowReportSuccessActionCreator extends IAction {
  workflowReportId: string;
}

/**
 * Creates success action when we have successfully deleted a workflow report
 * @param {String} workflowId
 * @returns {Object}
 */
export function deleteWorkflowReportSuccessActionCreator(
  workflowReportId: string
): IDeleteWorkflowReportSuccessActionCreator {
  return {
    type: types.DELETE_WORKFLOW_REPORT_SUCCESS,
    workflowReportId,
  };
}
/**
 * creates a reset activity_report selection action
 * @returns {Object}
 */
export function resetActivityReportSelectionAction(): IAction {
  return {
    type: types.RESET_ACTIVITY_REPORT_SELECTION,
  };
}

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearWorkflowDataAction(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function uploadFileBeginAction(): IBeginAction {
  return {
    type: types.UPLOAD_FILE_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function uploadFileSuccessAction(): IAction {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function uploadFileFailureAction(error: any): IActionError {
  return {
    type: types.UPLOAD_FILE_FAILURE,
    error,
  };
}

/**
 * Creates fetch workflowReports action when workflowReports are being fetched
 * @returns {Object}
 */
export function fetchWorkflowReportsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_WORKFLOW_REPORTS_BEGIN,
  };
}

export interface IFetchWorkflowReportsSuccessActionCreator extends IAction {
  workflow_reports: any[];
}

/**
 * creates a success action when we have successfully fetched workflow reports, pass in the fetched workflow reports
 * @param {Array} workflow_reports new workflow_reports fetched
 * @returns {Object}
 */
export function fetchWorkflowReportsSuccessActionCreator(
  workflow_reports: any[]
): IFetchWorkflowReportsSuccessActionCreator {
  return {
    type: types.FETCH_WORKFLOW_REPORTS_SUCCESS,
    workflow_reports,
  };
}

/**
 * Creates fetch workflow reports failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchWorkflowReportsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_WORKFLOW_REPORTS_FAILURE,
    error,
  };
}

/**
 * Creates fetch workflowReport action when workflowReports are being fetched
 * @returns {Object}
 */
export function fetchWorkflowReportBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_WORKFLOW_REPORT_BEGIN,
  };
}

export interface IFetchWorkflowReportSuccessActionCreator extends IAction {
  workflow_report: IWorkflowReportWithStepOverview;
}

/**
 * creates a success action when we have successfully fetched a workflow report, pass in the fetched workflow report
 * @param {Array} workflow_report workflow_report fetched
 * @returns {Object}
 */
export function fetchWorkflowReportSuccessActionCreator(
  workflow_report: any
): IFetchWorkflowReportSuccessActionCreator {
  return {
    type: types.FETCH_WORKFLOW_REPORT_SUCCESS,
    workflow_report,
  };
}

/**
 * Creates fetch workflow report failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchWorkflowReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_WORKFLOW_REPORT_FAILURE,
    error,
  };
}

/**
 * Creates fetch JobLogs action when jobLogs are being fetched
 * @returns {Object}
 */
export function fetchJobLogsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_JOB_LOGS_BEGIN,
  };
}

/**
 * Creates fetch job logs failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchJobLogsFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_JOB_LOGS_FAILURE,
    error,
  };
}

export interface IFetchJobLogsSuccessActionCreator extends IAction {
  steps_overview: any[];
}

/**
 * creates a success action when we have successfully fetched job logs, pass in the fetched job logs
 * @param {Array} steps_overview new steps_overview fetched
 * @returns {Object}
 */
export function fetchJobLogsSuccessActionCreator(
  steps_overview: any[]
): IFetchJobLogsSuccessActionCreator {
  return {
    type: types.FETCH_JOB_LOGS_SUCCESS,
    steps_overview,
  };
}

/**
 * Creates stop workflow report execution action when execution is being done
 * @returns {Object}
 */
export function stopWorkflowReportExecutionBeginActionCreator(): IBeginAction {
  return {
    type: types.STOP_WORKFLOW_REPORT_EXECUTION_BEGIN,
  };
}

/**
 * Creates stop workflow report execution failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function stopWorkflowReportExecutionFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.STOP_WORKFLOW_REPORT_EXECUTION_FAILURE,
    error,
  };
}
/**
 * creates a success action when we have successfully stopped stop the execution
 * @param {Array} steps_overview new steps_overview fetched
 * @returns {Object}
 */
export function stopWorkflowReportExecutionSuccessActionCreator(
  workflow_report: any
): IStopWorkflowReportExecutionSuccessActionCreator {
  return {
    type: types.STOP_WORKFLOW_REPORT_EXECUTION_SUCCESS,
    workflow_report,
  };
}
export interface IStopWorkflowReportExecutionSuccessActionCreator
  extends IAction {
  workflow_report: any;
}
export interface IResumeWorkflowReportExecutionSuccessActionCreator
  extends IAction {
  workflow_report: any;
}

/**
 * Creates resume workflow report execution action when execution is being done
 * @returns {Object}
 */
export function resumeWorkflowReportExecutionBeginActionCreator(): IBeginAction {
  return {
    type: types.RESUME_WORKFLOW_REPORT_EXECUTION_BEGIN,
  };
}

/**
 * Creates resume workflow report execution failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function resumeWorkflowReportExecutionFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.RESUME_WORKFLOW_REPORT_EXECUTION_FAILURE,
    error,
  };
}
/**
 * creates a success action when we have successfully resumed the execution
 * @param {Array} workflow_report the workflow report to resume
 * @returns {Object}
 */
export function resumeWorkflowReportExecutionSuccessActionCreator(
  workflow_report: any
): IResumeWorkflowReportExecutionSuccessActionCreator {
  return {
    type: types.RESUME_WORKFLOW_REPORT_EXECUTION_SUCCESS,
    workflow_report,
  };
}
export interface IResumeWorkflowReportExecutionSuccessActionCreator
  extends IAction {
  workflow_report: any;
}

/**
 * Action creator dispatched when creating a workflow report action is started
 * @returns {Object}
 */
export function createWorkflowReportBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_WORKFLOW_REPORT_BEGIN,
  };
}

/**
 * Failure/Error action creator when errors are caught when creating a workflow report
 * @param {Object} error
 * @returns {Object}
 */
export function createWorkflowReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_WORKFLOW_REPORT_FAILURE,
    error,
  };
}

export interface ICreateWorkflowReportSuccessActionCreator extends IAction {
  workflow_report: any;
}

export const createWorkflowReportSuccessActionCreator = (
  workflow_report: any
): ICreateWorkflowReportSuccessActionCreator => {
  return {
    type: types.CREATE_WORKFLOW_REPORT_SUCCESS,
    workflow_report,
  };
};

/**
 * Creates update workflow report action when a workflow is being updated
 * @returns {Object}
 */
export function updateWorkflowReportBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_WORKFLOW_REPORT_BEGIN,
  };
}

/**
 * Creates an update workflow report failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateWorkflowReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_WORKFLOW_REPORT_FAILURE,
    error,
  };
}

export interface IUpdateWorkflowReportSuccessActionCreator extends IAction {
  workflow_report: any;
}

/**
 * creates a success action when we have successfully updated a workflow report
 * @returns {Object}
 */
export function updateWorkflowReportSuccessActionCreator(
  workflow_report: any
): IUpdateWorkflowReportSuccessActionCreator {
  return {
    type: types.UPDATE_WORKFLOW_REPORT_SUCCESS,
    workflow_report,
  };
}
/* Activity report */

/**
 * Action creator dispatched when creating a activity report action is started
 * @returns {Object}
 */
export function createActivityReportBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_ACTIVITY_REPORT_BEGIN,
  };
}

/**
 * Failure/Error action creator when errors are caught when creating an activity report
 * @param {Object} error
 * @returns {Object}
 */
export function createActivityReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_ACTIVITY_REPORT_FAILURE,
    error,
  };
}

export interface ICreateActivityReportSuccessActionCreator extends IAction {
  activity_report: IActivityReport;
  files: any[];
}

export const createActivityReportSuccessActionCreator = (
  activity_report: any,
  files: any[]
): ICreateActivityReportSuccessActionCreator => {
  return {
    type: types.CREATE_ACTIVITY_REPORT_SUCCESS,
    activity_report,
    files,
  };
};
/**
 * Creates update activity_report action when a activity_report is being updated
 * @returns {Object}
 */
export function updateActivityReportBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_ACTIVITY_REPORT_BEGIN,
  };
}

/**
 * Creates an update activity_report failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateActivityReportFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_ACTIVITY_REPORT_FAILURE,
    error,
  };
}

export interface IUpdateActivityReportSuccessActionCreator extends IAction {
  activity_report: any;
}

/**
 * creates a success action when we have successfully updated a activity_report, pass in the updated activity_report
 * @param {Object} activity_report new activity_report updated
 * @returns {Object}
 */
export function updateActivityReportSuccessActionCreator(
  activity_report: IActivityReport
): IUpdateActivityReportSuccessActionCreator {
  return {
    type: types.UPDATE_ACTIVITY_REPORT_SUCCESS,
    activity_report,
  };
}

export interface ISetActivityReportSortingAction extends IAction {
  sorting: ITableSorting;
}

export function setActivityReportSortingActionCreator(
  sorting: ITableSorting
): ISetActivityReportSortingAction {
  return {
    type: types.SET_ACTIVITY_REPORT_SORTING,
    sorting,
  };
}

export function editQuestionTagBeginAction(): IBeginAction {
  return { type: types.CHANGE_LIST_QUESTION_TAG_BEGIN };
}
export function editQuestionTagFailureAction(error: any): IActionError {
  return {
    type: types.CHANGE_LIST_QUESTION_TAG_FAILURE,
    error,
  };
}

export interface IEditQuestionTagSuccessAction extends IAction {
  params: IChangeTagParams;
  activityId: string;
}
export function editQuestionTagSuccessAction(
  params: IChangeTagParams,
  activityId: string
): IEditQuestionTagSuccessAction {
  return {
    type: types.CHANGE_LIST_QUESTION_TAG_SUCCESS,
    params,
    activityId,
  };
}
