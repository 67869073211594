import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Tab, Tabs } from "components/Tab";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useTranslations from "hooks/useTranslations";
import IPicture from "model/entities/Picture";

import { IImageDetectionDataFE } from "../types";
import PictureDetails from "./PictureDetails";
import ScanResultsTab, { IScanResultsTabProps } from "./ScanResultsTab";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 75px)",
    background: "white",
    overflowY: "auto",
    paddingBottom: "16px",
  },
  scrollBox: {
    height: "80vh",
    overflowY: "auto",
  },
}));

export interface IPictureSideBarProps
  extends Pick<
    IScanResultsTabProps,
    "onClickScanResult" | "onHoverScanResult" | "activeBoxIds"
  > {
  toggleDisplayDetections?: (value: boolean) => void;
  isLoadingScanResults?: boolean;
  picture: IPicture;
  detections: IImageDetectionDataFE[];
}

function PictureSideBar({
  picture,
  detections,
  activeBoxIds,
  toggleDisplayDetections,
  isLoadingScanResults,
  onClickScanResult,
  onHoverScanResult,
}: IPictureSideBarProps) {
  const client = useSelector(getSelectedClient);
  const showScanResultsTab = !_.isEmpty(client.detectable_lists);
  const lang = useTranslations();
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;
  const classes = useStyles();

  function enableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(true);
    }
  }
  function disableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(false);
    }
  }

  return (
    <Box className={classes.root}>
      <Tabs animateTabSwitch>
        <Tab
          id="1"
          label={
            lang.containers.workflows.subCategories.activityReports.detailDialog
              .details
          }
          style={{
            paddingLeft: "8px",
          }}
        >
          <PictureDetails picture={picture} />
        </Tab>
        <Tab
          id="2"
          label={langKey.detectionsSideBar.options.scanResults}
          overflow="hidden"
          hidden={!showScanResultsTab}
        >
          <Box className={classes.scrollBox}>
            <ScanResultsTab
              activeBoxIds={activeBoxIds}
              isLoadingScanResults={isLoadingScanResults}
              detections={detections}
              onMount={enableDisplayDetections}
              onUnmount={disableDisplayDetections}
              onClickScanResult={onClickScanResult}
              onHoverScanResult={onHoverScanResult}
            />
          </Box>
        </Tab>
      </Tabs>
    </Box>
  );
}

export default PictureSideBar;
