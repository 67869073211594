import { IVisitEvent } from "fieldpro-tools";
import Moment from "moment";

interface IEventIsWithinAllowedHours {
  event: IVisitEvent;
  maxEventEndTime: Moment.Moment;
  minEventStartTime: Moment.Moment;
  moment: typeof Moment;
}
export function eventIsWithinAllowedHours({
  event,
  maxEventEndTime,
  minEventStartTime,
  moment,
}: IEventIsWithinAllowedHours) {
  if (!event.start_time || !event.end_time) return false;
  const start_time = moment(event.start_time);
  const end_time = moment(event.end_time);

  const minEventStartTimeSameDay = moment({
    hour: minEventStartTime.hour(),
    minute: minEventStartTime.minute(),
  });
  const maxEventEndTimeSameDay = moment({
    hour: maxEventEndTime.hour(),
    minute: maxEventEndTime.minute(),
  });

  return (
    start_time.utc().hour() >= minEventStartTimeSameDay.utc().hour() &&
    end_time.utc().hour() <= maxEventEndTimeSameDay.utc().hour()
  );
}
