import { ICustomersMapKpiData, TKpiMapLegend } from "fieldpro-tools";
import _ from "lodash";

import { IKPI } from "model/entities/Dashboard";

import CustomersMapChartBaseMap from "./CustomersMapChartBaseMap";
import LegendContainer from "./LegendContainer";
import { getCustomerFeaturesFromChart } from "./utils/getCustomerFeaturesFromChart";

export interface ICustomersMapChartProps {
  chart: IKPI<Array<ICustomersMapKpiData>>;
  legend?: TKpiMapLegend;
}

function CustomersMapChart({ chart, legend }: ICustomersMapChartProps) {
  const customerFeatures = getCustomerFeaturesFromChart(chart);

  return (
    <CustomersMapChartBaseMap
      key={_.size(customerFeatures)}
      customerFeatures={customerFeatures}
    >
      <LegendContainer legend={legend} />
    </CustomersMapChartBaseMap>
  );
}

export default CustomersMapChart;
