import { useEffect } from "react";

import { Breadcrumbs, makeStyles, Typography } from "@material-ui/core";
import { FOLDER_TYPE } from "fieldpro-tools";
import _ from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { bindActionCreators } from "redux";

import { allClientsComposedSelector } from "containers/clients/redux/selectors";
import * as placeActions from "containers/customers/redux/actions";
import * as dashboardActions from "containers/dashboards/redux/actions";
import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import * as documentActions from "containers/documents/redux/actions";
import { getDocuments } from "containers/documents/redux/selectors";
import * as environmentActions from "containers/environments/redux/actions";
import { getAllFolders } from "containers/folders/redux/selectors";
import * as listActions from "containers/lists/redux/actions";
import { listsComposedSelector } from "containers/lists/redux/selectors";
import * as teamActions from "containers/teams/redux/actions";
import { teamsComposedSelector } from "containers/teams/redux/selectors";
import * as userActions from "containers/users/redux/actions/userActions";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { allWebUsersSelector } from "containers/users/redux/selectors/webUsersSelector";
import * as workflowActions from "containers/workflows/redux/actions";
import {
  allActivitiesSelector,
  workflowsComposedSelector,
} from "containers/workflows/redux/selectors";
import useTranslations from "hooks/useTranslations";
import getFullName from "utils/formatting/getFullName";

const routes = [];

const useStyles = makeStyles(() => ({
  container: {
    height: "24px",
    marginBottom: "8px",
  },
  breadcrumbTitle: {
    color: "grey",
    fontSize: "12px",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
const AppBreadcrumbs = ({
  breadcrumbs,
  dashboards,
  documents,
  folders,
  lists,
  clients,
  activities,
  workflows,
  webUsers,
  mobileUsers,
  userActions,
  documentActions,
  workflowActions,
  dashboardActions,
  listActions,
  teamActions,
  placeActions,
  environmentActions,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const lang = useTranslations();

  useEffect(() => {
    const makeBreadCrumb = (path, value) => {
      return { path: `/${path}`, breadcrumb: value };
    };
    const addBreadCrumb = (breadCrumb) => {
      if (!_.find(routes, breadCrumb)) {
        routes.push(breadCrumb);
      }
    };
    const homeBreadCrumb = makeBreadCrumb("", lang.mainLayout.sidebar.home);
    addBreadCrumb(homeBreadCrumb);

    const allPossibleRoutes = _.merge(lang.actions, lang.mainLayout.sidebar);

    _.map(_.keys(allPossibleRoutes), function (key) {
      if (key === "webuser" || key === "mobileuser") return;
      addBreadCrumb(makeBreadCrumb(key, allPossibleRoutes[key]));
      addBreadCrumb(makeBreadCrumb(`*/${key}`, allPossibleRoutes[key]));
    });
  }, [lang.actions, lang.mainLayout.sidebar]);

  if (_.find(breadcrumbs, ({ location }) => location?.pathname === "/home")) {
    return null;
  }

  const actions = {
    dashboards: dashboardActions.changeSubcategoryAction,
    teams: teamActions.changeSubcategoryAction,
    lists: listActions.changeSubcategoryAction,
    places: placeActions.changeSubcategoryAction,
    environment: environmentActions.changeSubcategoryAction,
    workflows: workflowActions.changeSubcategoryAction,
    documents: documentActions.changeSubcategoryAction,
    users: userActions.changeSubcategoryAction,
  };

  const noBreacrumbSections = ["home", "calendar"];

  const allFolders = (dashboards || []).map((f) => ({
    name: f.folder || "default",
    description: "",
    dashboards: [],
  }));

  const handleOnClick = (link) => {
    const section = link.split("/")[1];
    const targetCategory = link.split("/").pop();

    // Why ? this seems buggy for some pages like /clients
    // if (!changeSubCategory) {
    //   history.push("/");
    //   return;
    // }

    let selectedFolder;
    if (["dashboards", "environment"].includes(section)) {
      selectedFolder = allFolders.find(
        (f) => f.name.toLowerCase().replaceAll(" ", "_") === targetCategory
      );
    }

    const changeSubCategory = actions[section];
    if (changeSubCategory) {
      changeSubCategory(
        section === targetCategory &&
          !["users", "teams"].includes(targetCategory)
          ? "index"
          : section === targetCategory && targetCategory === "users"
          ? "mobileuser"
          : section === targetCategory && targetCategory === "teams"
          ? "team"
          : selectedFolder
          ? selectedFolder.name
          : targetCategory
      );
    }

    history.push(link);
  };
  const removeExtensionFromFile = (filename) => {
    const splits = filename.split(".");
    const cleanName =
      splits.length === 1 ? filename : splits.slice(0, -1).join(".");
    return cleanName;
  };
  const getBreadCrumbTitle = (link, title) => {
    const links = link.split("/");
    const targetCategory = links.length !== 0 && links[links.length - 1];
    const section = links[1];
    if (targetCategory !== section) {
      switch (section) {
        case "lists":
          const selectedList = lists.find((l) => l.id === targetCategory);
          title = selectedList?.name || title;
          break;
        case "dashboards":
          const dashboard_folders = folders.filter(
            (f) => f.type === FOLDER_TYPE.DASHBOARD_FOLDER
          );
          const selectObject = [...dashboard_folders, ...dashboards].find(
            (f) => f.id === targetCategory
          );
          title = selectObject?.name || selectObject?.title || title;
          break;
        case "users":
          const selectedUser =
            targetCategory !== "mobileuser" &&
            targetCategory !== "webuser" &&
            [...mobileUsers, ...webUsers].find((u) => u.id === targetCategory);

          title =
            targetCategory === "create"
              ? "Create New User"
              : title.props.children === "Webuser"
              ? lang.mainLayout.sidebar.webUsers
              : title.props.children === "Mobileuser"
              ? lang.mainLayout.sidebar.mobileUsers
              : getFullName(selectedUser);

          break;

        case "clients":
          const selectedClient = clients.find((u) => u.id === targetCategory);
          title =
            targetCategory === "create"
              ? lang.containers.clients.subCategories.clients.createEditModal
                  .createTitle
              : selectedClient?.name || "";
          break;

        case "workflows":
          const selectedActivityOrWorkflow =
            targetCategory !== "activities" &&
            targetCategory !== "workflows" &&
            [...activities, ...workflows].find((l) => l.id === targetCategory);
          title = selectedActivityOrWorkflow?.name || title;
          break;
        case "documents":
          let object = folders.find(
            (l) =>
              l.id === targetCategory && l.type === FOLDER_TYPE.DOCUMENT_FOLDER
          );
          if (!object) {
            object = documents.find((l) => l.id === targetCategory);
          }

          title = object
            ? removeExtensionFromFile(object?.name)
            : targetCategory;
          break;
        default:
          break;
      }
    } else {
      switch (section) {
        case "dashboards": {
          const selectedFolder = folders
            .filter((f) => f.type === FOLDER_TYPE.DASHBOARD_FOLDER)
            .find((f) => f.id === targetCategory);
          title = selectedFolder?.name || title;
          break;
        }
        default:
          break;
      }
    }
    return title;
  };
  return (
    breadcrumbs.length > 1 &&
    !noBreacrumbSections.includes(breadcrumbs[1].match.url.split("/")[1]) && (
      <div className={classes.container}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs.map(({ breadcrumb, match }, index) => {
            return (
              <Typography
                key={index}
                className={classes.breadcrumbTitle}
                onClick={() => {
                  return (
                    index !== breadcrumbs.length - 1 && handleOnClick(match.url)
                  );
                }}
              >
                {getBreadCrumbTitle(match.url, breadcrumb)}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </div>
    )
  );
};
function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    workflowActions: bindActionCreators(workflowActions, dispatch),
    listActions: bindActionCreators(listActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
    environmentActions: bindActionCreators(environmentActions, dispatch),
    placeActions: bindActionCreators(placeActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    dashboards: dashboardsComposedSelector(state),
    lists: listsComposedSelector(state),
    teams: teamsComposedSelector(state),
    clients: allClientsComposedSelector(state),
    workflows: workflowsComposedSelector(state),
    activities: allActivitiesSelector(state),
    folders: getAllFolders(state),
    webUsers: allWebUsersSelector(state),
    mobileUsers: allMobileUsersSelector(state),
    documents: getDocuments(state),
  };
}

export default withBreadcrumbs(routes)(
  connect(mapStateToProps, mapDispatchToProps)(AppBreadcrumbs)
);
