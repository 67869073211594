import React, { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { Moment } from "moment";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { GreyDark, GreyLight, StrokeLowEnphasis } from "assets/colors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";
import { IClient } from "model/entities/Client";

import Board, { IBoardProps } from "./Board";
import { computeNewDraggedEvent } from "./utils/computeNewDraggedEvent";
import { generateWorkingDates } from "./utils/dateStepper";
import { getTimesOfDay } from "./utils/getTimesOfDay";
import { shouldShowBoardItem } from "./utils/reapeatingEventsUtils";

export interface ICalendarProps
  extends Pick<
    IBoardProps,
    | "eventPlaceHolders"
    | "activeBoardItems"
    | "onClickApproveEvent"
    | "onClickDeclineEvent"
  > {
  height?: string | number;
  startDate: string | Date;
  defaultEvents: TCalendarEvent[];
  draggable?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  onChange?: (events: TCalendarEvent[]) => void;
  onClickTimeSlot?: (dateTime: Moment) => void;
  onEditEvent: (event: TCalendarEvent) => void;
  onClickDeleteEvent: (event: TCalendarEvent) => void;
  scrollAnchor?: React.RefObject<HTMLDivElement>;
  repeatingEvents?: TCalendarEvent[];
}

export function getCalendarNumberOfDays(client?: IClient) {
  if (client?.enable_visit_planning_weekends) return 7;
  return 5;
}
export const BOX_HEIGHT = 55;
interface ICalendarStylesProps {
  width: string;
  height: string | number;
  daysCount: number;
  minWidth?: string;
  maxWidth?: string;
  draggable?: boolean;
}

const useStyles = makeStyles(() => ({
  container: ({
    width,
    maxWidth,
    minWidth,
    height,
    daysCount,
  }: ICalendarStylesProps) => ({
    height,
    overflowY: "scroll",
    overflowX: "hidden",
    width: width,
    background: "white",
    display: "grid",
    gridTemplateColumns: `50px repeat(${daysCount},1fr)`,
    gridcolumnGap: "0px",
    boxShadow: `inset 0px 5px 3px -3px ${GreyLight}`,
    paddingTop: "0.2px",
    maxWidth,
    minWidth,
  }),
  day: {
    borderRight: `2px solid ${StrokeLowEnphasis}`,
  },
  boardContainer: ({
    width,
    daysCount,
    minWidth,
    maxWidth,
  }: ICalendarStylesProps) => ({
    width: `calc(${width} / ${daysCount})`,
    minWidth: minWidth,
    maxWidth: maxWidth,
  }),
  dialogHeader: ({ width }: ICalendarStylesProps) => ({
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15)",
    background: "white",
    width: width,
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    alignContent: "center",
    alignItems: "center",
    justifyItems: "space-around",
  }),
  box: {
    borderBottom: `2px solid ${StrokeLowEnphasis}`,
    height: `${BOX_HEIGHT}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    background: "white",
    color: GreyDark,
    fontSize: "13px",
    paddingTop: "8px",
  },
}));

function Calendar({
  startDate,
  defaultEvents,
  draggable = true,
  width = "100vw",
  minWidth,
  maxWidth,
  onChange,
  onClickTimeSlot,
  height = "fit-content",
  onEditEvent,
  onClickDeleteEvent,
  eventPlaceHolders,
  activeBoardItems,
  scrollAnchor,
  onClickApproveEvent,
  onClickDeclineEvent,
}: Readonly<ICalendarProps>) {
  const client = useSelector(getSelectedClient);
  const { moment, timeZone } = useMomentTimeZone();
  const TIMES_OF_DAY = getTimesOfDay(client, moment);
  const DAYS_COUNT = getCalendarNumberOfDays(client);
  const classes = useStyles({
    width,
    daysCount: DAYS_COUNT,
    minWidth,
    maxWidth,
    draggable,
    height,
  });
  const dates = generateWorkingDates(
    moment(startDate).toDate(),
    DAYS_COUNT,
    moment
  );
  const [events, setEvents] = useState<TCalendarEvent[]>(defaultEvents);

  const handleDragEnd: OnDragEndResponder = (result) => {
    const newEvents = computeNewDraggedEvent({
      dropResult: result,
      events,
      moment,
    });
    if (!_.isEqual(events, newEvents)) {
      setEvents(newEvents);
      onChange && onChange(newEvents);
    }
  };

  useEffect(() => {
    setEvents(defaultEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultEvents)]);

  return (
    <Box>
      <Box
        className={classes.dialogHeader}
        key={JSON.stringify(dates) + timeZone}
      >
        <Box>{/* Empty space */}</Box>
        <Box display={"grid"} gridAutoFlow={"column"}>
          {_.map(dates, (date) => (
            <Box
              key={date}
              textAlign={"center"}
              paddingTop={"6px"}
              paddingBottom={"6px"}
              fontWeight={"500"}
            >
              {moment(date).format("ddd D")}
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.container} key={DAYS_COUNT}>
        <Box
          style={{
            background: "white",
            height: `calc(${TIMES_OF_DAY.length} * ${BOX_HEIGHT}px)`,
            borderRight: `2px solid ${StrokeLowEnphasis}`,
          }}
        >
          {_.map(TIMES_OF_DAY, (time) => (
            <div
              className={classes.box}
              key={time}
              ref={time === "6AM" ? scrollAnchor : undefined}
            >
              {time}
            </div>
          ))}
        </Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          {_.map(dates, (date) => {
            const matchingEvents = events.filter((event) =>
              moment(event.start_time)
                .startOf("day")
                .isSame(moment(date).startOf("day"))
            );
            const matchingPlaceHolders = eventPlaceHolders?.filter(
              (placeholderEvent) =>
                shouldShowBoardItem(placeholderEvent, date, moment)
            );

            return (
              <Board
                key={date}
                events={matchingEvents}
                boardId={date}
                boardContainerProps={{
                  className: classes.day,
                }}
                draggable={draggable}
                onClickTimeSlot={onClickTimeSlot}
                onEditEvent={onEditEvent}
                onClickDeleteEvent={onClickDeleteEvent}
                onClickApproveEvent={onClickApproveEvent}
                onClickDeclineEvent={onClickDeclineEvent}
                eventPlaceHolders={matchingPlaceHolders}
                activeBoardItems={activeBoardItems}
              />
            );
          })}
        </DragDropContext>
      </Box>
    </Box>
  );
}

export default Calendar;
