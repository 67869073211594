import { IVisitEvent } from "fieldpro-tools";
import Moment from "moment";

export function getEventRowPosition(
  event: IVisitEvent,
  calendarstartTime: moment.Moment | Date,
  moment: typeof Moment
) {
  const startOffSet = Math.floor(moment(event.start_time).minutes() / 30);
  const startDiffFromStartTime = moment(event.start_time).diff(
    calendarstartTime,
    "hours"
  );

  const duration =
    Math.ceil(moment(event.end_time).diff(event.start_time, "minutes") / 30) *
    30;
  const durationNormalized = Math.floor(duration / 30);

  //prettier-ignore
  return `${(startDiffFromStartTime * 2 + 1) +  startOffSet} / span ${durationNormalized}`;
}

export function calculateMarginTop(event: any, moment: typeof Moment) {
  const minuteOffset = moment(event.start_time).minutes() % 30;
  const pixelsPerMinute = 0.5;
  return minuteOffset * pixelsPerMinute;
}
