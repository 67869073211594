import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Dashboard from "components/Dashboard/Dashboard";
import { getQuery } from "components/Filter/Filter.utils";
import FilterDropdownManager from "components/Filter/FilterDropdownManager";
import { getActionsAvailableSelector } from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { computeDashboardAction } from "containers/dashboards/redux/actions";
import { prepareFilters } from "containers/dashboards/subcategories/prepareFilters";
import runQuery from "containers/dashboards/utils/runQuery";
import { allListsSelector } from "containers/lists/redux/selectors";
import { activeTeamsComposedSelector } from "containers/teams/redux/selectors";
import {
  allMobileUsersSelector,
} from "containers/users/redux/selectors/mobileUsersSelector";
import { allActiveWorkflowsSelector } from "containers/workflows/redux/selectors";
import { useActionsLegacy } from "hooks/useActions";
import { DEFAULT_DATE_FILTER } from "model/entities/Client";
import { IDashboard } from "model/entities/Dashboard";

interface IItemInsights {
  dashboard: IDashboard;
  itemId: string;
}

const ItemInsights = ({ dashboard, itemId }: IItemInsights) => {
  const classes = {};

  const computeDashboard = useActionsLegacy(computeDashboardAction);
  const actionsAvailable = useSelector(getActionsAvailableSelector);
  const client = useSelector(getSelectedClient);
  const workflows = useSelector(allActiveWorkflowsSelector);
  const lists = useSelector(allListsSelector);
  const teams = useSelector(activeTeamsComposedSelector);
  const users = useSelector(allMobileUsersSelector);
  const initFilters = prepareFilters(
    dashboard,
    client,
    workflows,
    lists,
    teams,
    users,
    client.mobile_user_business_roles,
    DEFAULT_DATE_FILTER.LAST_30_DAYS,
    [] // table optimizations
  );

  const [filters, setFilters] = useState(initFilters);

  useEffect(() => {
    if (!computeDashboard) {
      return;
    }
    const query = {
      ...getQuery(filters, dashboard),
      param1: itemId,
    };

    runQuery({
      dashboard,
      query,
      actionsAvailable,
      teams,
      computeDashboard,
    }).catch((e: any) => {
      alert(
        `Error when trying to run runQuery (from the useEffect of ItemInsights): ${e.message}`
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, computeDashboard]);

  const onRunQuery = (
    dashboard: IDashboard,
    query: any,
    callback: () => any,
    degradedModeKpis?: string[]
  ) => {
    return runQuery({
      dashboard,
      query,
      callback,
      actionsAvailable,
      teams,
      computeDashboard,
      degradedModeKpis,
    });
  };

  return (
    <Box
      /* 2px h-padding to avoid cropping the Scorecards' box-shadows */
      margin="0px 2px 24px 2px"
    >
      <FilterDropdownManager filters={filters} onChangeFilters={setFilters} />

      <Box>
        <Dashboard
          classes={classes}
          filters={filters}
          dashboard={dashboard}
          onRunQuery={onRunQuery}
        />
      </Box>
    </Box>
  );
};

export default ItemInsights;
