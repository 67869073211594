import { IClient, MOMENT_TIMEZONES, TIMEZONE } from "fieldpro-tools";
import moment from "moment-timezone";

export const getMomentObject = (client: IClient) => {
  const momentTimeZone = clientTimeZoneToMomentTimeZone(client);

  moment.tz.setDefault(momentTimeZone);

  return moment;
};

export const clientTimeZoneToMomentTimeZone = (client: IClient) => {
  const clientTimeZone = client.timezone;

  if (!clientTimeZone) return MOMENT_TIMEZONES.UTC;
  return (
    CLIENT_TIMEZONE_TO_MOMENT_TIMEZONE[clientTimeZone as TIMEZONE] ||
    MOMENT_TIMEZONES.UTC
  );
};

const CLIENT_TIMEZONE_TO_MOMENT_TIMEZONE: Record<TIMEZONE, MOMENT_TIMEZONES> = {
  UTC: MOMENT_TIMEZONES.UTC,
  ACSST: MOMENT_TIMEZONES["Australia/Adelaide"],
  ACST: MOMENT_TIMEZONES["Australia/Darwin"],
  ACT: MOMENT_TIMEZONES["Australia/Sydney"],
  NZDT: MOMENT_TIMEZONES["Pacific/Auckland"],
  IDLE: MOMENT_TIMEZONES["Pacific/Kiritimati"],
  NZST: MOMENT_TIMEZONES["Pacific/Auckland"],
  NZT: MOMENT_TIMEZONES["Pacific/Auckland"],
  AESST: MOMENT_TIMEZONES["Australia/Sydney"],
  CADT: MOMENT_TIMEZONES["Australia/Sydney"],
  SADT: MOMENT_TIMEZONES["Australia/Adelaide"],
  AEST: MOMENT_TIMEZONES["Australia/Sydney"],
  EAST: MOMENT_TIMEZONES["Pacific/Easter"],
  GST: MOMENT_TIMEZONES["Asia/Dubai"],
  LIGT: MOMENT_TIMEZONES["Pacific/Efate"],
  SAST: MOMENT_TIMEZONES["Africa/Johannesburg"],
  CAST: MOMENT_TIMEZONES["Australia/Adelaide"],
  AWSST: MOMENT_TIMEZONES["Australia/Perth"],
  JST: MOMENT_TIMEZONES["Asia/Tokyo"],
  KST: MOMENT_TIMEZONES["Asia/Seoul"],
  MHT: MOMENT_TIMEZONES["Pacific/Majuro"],
  WDT: MOMENT_TIMEZONES["Australia/Perth"],
  MT: MOMENT_TIMEZONES["Asia/Tehran"],
  AWST: MOMENT_TIMEZONES["Australia/Perth"],
  CCT: MOMENT_TIMEZONES["Asia/Shanghai"],
  WADT: MOMENT_TIMEZONES["Australia/Perth"],
  WST: MOMENT_TIMEZONES["Australia/Perth"],
  JT: MOMENT_TIMEZONES["Asia/Jakarta"],
  ALMST: MOMENT_TIMEZONES["Asia/Almaty"],
  WAST: MOMENT_TIMEZONES["Asia/Bangkok"],
  CXT: MOMENT_TIMEZONES["Indian/Christmas"],
  ALMT: MOMENT_TIMEZONES["Asia/Almaty"],
  MAWT: MOMENT_TIMEZONES["Antarctica/Mawson"],
  IOT: MOMENT_TIMEZONES["Indian/Chagos"],
  MVT: MOMENT_TIMEZONES["Indian/Maldives"],
  TFT: MOMENT_TIMEZONES["Indian/Kerguelen"],
  AFT: MOMENT_TIMEZONES["Asia/Kabul"],
  MUT: MOMENT_TIMEZONES["Indian/Mauritius"],
  RET: MOMENT_TIMEZONES["Indian/Reunion"],
  SCT: MOMENT_TIMEZONES["Indian/Mahe"],
  IT: MOMENT_TIMEZONES["Asia/Kolkata"],
  EAT: MOMENT_TIMEZONES["Africa/Nairobi"],
  BT: MOMENT_TIMEZONES["Asia/Dhaka"],
  EETDST: MOMENT_TIMEZONES["Europe/Athens"],
  HMT: MOMENT_TIMEZONES["Asia/Colombo"],
  BDST: MOMENT_TIMEZONES["Europe/London"],
  CEST: MOMENT_TIMEZONES["Europe/Berlin"],
  CETDST: MOMENT_TIMEZONES["Europe/Paris"],
  EET: MOMENT_TIMEZONES["Europe/Athens"],
  FWT: MOMENT_TIMEZONES["Europe/Paris"],
  IST: MOMENT_TIMEZONES["Asia/Kolkata"],
  MEST: MOMENT_TIMEZONES["Europe/Paris"],
  METDST: MOMENT_TIMEZONES["Europe/Berlin"],
  SST: MOMENT_TIMEZONES["Pacific/Pago_Pago"],
  BST: MOMENT_TIMEZONES["Europe/London"],
  CET: MOMENT_TIMEZONES["Europe/Paris"],
  DNT: MOMENT_TIMEZONES["Europe/Oslo"],
  FST: MOMENT_TIMEZONES["Europe/Paris"],
  MET: MOMENT_TIMEZONES["Europe/Berlin"],
  MEWT: MOMENT_TIMEZONES["Europe/Madrid"],
  MEZ: MOMENT_TIMEZONES["Europe/Berlin"],
  NOR: MOMENT_TIMEZONES["Europe/Oslo"],
  SET: MOMENT_TIMEZONES["Europe/Stockholm"],
  SWT: MOMENT_TIMEZONES["Europe/Zurich"],
  WETDST: MOMENT_TIMEZONES["Europe/Lisbon"],
  GMT: MOMENT_TIMEZONES["Europe/London"],
  UT: MOMENT_TIMEZONES.UTC,
  Z: MOMENT_TIMEZONES.UTC,
  ZULU: MOMENT_TIMEZONES.UTC,
  WET: MOMENT_TIMEZONES["Europe/Lisbon"],
  WAT: MOMENT_TIMEZONES["Africa/Lagos"],
  NDT: MOMENT_TIMEZONES["America/St_Johns"],
  ADT: MOMENT_TIMEZONES["America/Halifax"],
  AWT: MOMENT_TIMEZONES["America/New_York"],
  NFT: MOMENT_TIMEZONES["Pacific/Norfolk"],
  NST: MOMENT_TIMEZONES["America/St_Johns"],
  AST: MOMENT_TIMEZONES["America/Halifax"],
  EDT: MOMENT_TIMEZONES["America/New_York"],
  CDT: MOMENT_TIMEZONES["America/Chicago"],
  EST: MOMENT_TIMEZONES["America/New_York"],
  CST: MOMENT_TIMEZONES["America/Chicago"],
  MDT: MOMENT_TIMEZONES["America/Denver"],
  MST: MOMENT_TIMEZONES["America/Denver"],
  PDT: MOMENT_TIMEZONES["America/Los_Angeles"],
  AKDT: MOMENT_TIMEZONES["America/Juneau"],
  PST: MOMENT_TIMEZONES["America/Los_Angeles"],
  YDT: MOMENT_TIMEZONES["America/Whitehorse"],
  AKST: MOMENT_TIMEZONES["America/Anchorage"],
  HDT: MOMENT_TIMEZONES["Pacific/Honolulu"],
  YST: MOMENT_TIMEZONES["America/Whitehorse"],
  AHST: MOMENT_TIMEZONES["America/Anchorage"],
  HST: MOMENT_TIMEZONES["Pacific/Honolulu"],
  CAT: MOMENT_TIMEZONES["Africa/Harare"],
  NT: MOMENT_TIMEZONES["Pacific/Midway"],
  IDLW: MOMENT_TIMEZONES["Pacific/Kiritimati"],
};
